import React, { useState } from 'react'
import { InputFields } from '../../UI/InputFields/InputFields'
import { DoubleButton } from '../DoubleButton/DoubleButton'
import { useUpdateProfilePassword } from '../../modules/Profile/api/useUpdateProfilePassword'

const UserPasswordChange = ({ changeEdit }) => {

    const { handlerUpdatePass } = useUpdateProfilePassword()

    const [userPassword, setUserPassword] = useState({
        current_password: '',
        new_password: '',
    })

    return (
        <div className="profile_change_block">
            <InputFields
                textLabel={'Текущий пароль'}
                id={'profile_current_pass'}
                onChange={(event) =>
                    setUserPassword({ ...userPassword, current_password: event.target.value })
                }
            />
            <InputFields
                textLabel={'Новый пароль'}
                id={'profile_new_pass'}
          
                onChange={(event) =>
                    setUserPassword({ ...userPassword, new_password: event.target.value })
                }
            />
            {/* <InputFields
                textLabel={'Повторите пароль'}
                id={'profile_check_new_pass'}
       
                onChange={(event) =>
                    setUserPassword({ ...userPassword, check_new_pass: event.target.value })
                }
            /> */}
            <DoubleButton
                leftTitle={'Отмена'}
                leftClassName={'bg_white'}
                rightTitle={'Сохранить'}
                rightClassName={'bg_dark'}
                leftOnClick={() => changeEdit(true, true, true)}
                rightOnClick={() => handlerUpdatePass(userPassword)}
            />
        </div>
    )
}

export { UserPasswordChange }
