import React, { useState } from 'react'
import { useEffect } from 'react'
import { MainButton } from '../../../UI/MainButton/MainButton'
import { Modal } from '../../../UI/Modal/Modal'
import { MultipleRadio } from '../../MultipleRadio/MultipleRadio'
import { useUpdateTariffUser } from '../../../modules/Profile/api/useUpdateTariffUser'
import './ModalTariff.css'

const ModalTariff = ({ activeModal, setActiveModal, dataTariff }) => {
    const [radioValue, setRadioValue] = useState('')
    const [nameTariff, setNameTariff] = useState('')

    const { handlerUpdateTariff } = useUpdateTariffUser()

    useEffect(() => {
        let i = 0
        while (i < 7) {
            if (i == radioValue) {
                setNameTariff(dataTariff.result[i - 1]?.name)
            }
            i++
        }
    }, [radioValue])

    return (
        <Modal close={setActiveModal} active={activeModal}>
            <div className="modal_tariff_list">
                <div className="modal_title">Выбор тарифа</div>
                {dataTariff.modalTariff.map((tariff, index) => {
                    return (
                        <div className="modal_tariff_item" key={index}>
                            <div className="modal_tariff_item_title">
                                {tariff.title}
                            </div>
                            <ul>
                                {tariff.description.map((e, index) => {
                                    return <li key={index}>{e}</li>
                                })}
                            </ul>
                            <MultipleRadio
                                data={tariff.values}
                                radioValue={radioValue}
                                setRadioValue={setRadioValue}
                            />
                        </div>
                    )
                })}
            </div>
            <div className="modal_tariff_description">
                К оплате принимаются платёжные системы:<br></br>
                Visa, Mastercard, Мир, maestro, Apple Pay, Google Pay, Wechat
                Pay, Alipay, PayMaster, ЮMoney
            </div>
            {radioValue ? (
                <div className="modal_tariff_counter_tariff">{`Выбраный тариф: ${nameTariff}`}</div>
            ) : null}
            <MainButton
                title="Выбрать тариф"
                className="bg_dark"
                onClick={() => handlerUpdateTariff({ tarif: Number(radioValue) })}
            />
        </Modal>
    )
}

export { ModalTariff }
