import { useReleaseUpdateInfoMutation } from "./releaseListAPI"


export function useReleaseUpdateInfo() {

    const [updateInfo, {isLoading}] = useReleaseUpdateInfoMutation()

    const handlerUpdateInfo = async (Obj) => {

        try {
            const releaseInfo = await updateInfo(Obj).unwrap()
            console.log(releaseInfo)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }

    }

    return {
        handlerUpdateInfo
    }
}