import React from 'react'
import './ImgLink.css'

const ImgLink = ({ link, rightText, color, ...props}) => {
    return (
        <div className='wrapper_img_link' style={{color: color}} {...props}>
            <span>
                <img src={link} alt="img" />
            </span>
            <span>{rightText}</span>
        </div>
    )
}

export { ImgLink }
