import React from 'react'
import { useState } from 'react'
import { ModalTariff } from '../../../../components/Modals/ModalTariff/ModalTariff'
import { ImgLink } from '../../../../UI/ImgLink/ImgLink'
import { useGetTariffListQuery } from '../../api/profileAPI'
import { useModal } from '../../../../components/hooks/useModal'


import './TariffProfile.css'

const TariffProfile = ({ data }) => {

    
    const { data: dataTariff } = useGetTariffListQuery()
    const { modalIsOpen } = useModal()
    const [activeModal, setActiveModal] = useState(false)
    


    return (
        <div className="profile_tarif_container">
            {activeModal ? (
                <ModalTariff
                    activeModal={activeModal}
                    setActiveModal={setActiveModal}
                    dataTariff={dataTariff}
                />
            ) : null}
            <div className="tarif_title_wrapper">
                <span>Тарифы</span>
                <ImgLink
                    link={'assets/icon/icon_pencil.svg'}
                    rightText={
                        data.tarif_id ? 'Изменить тариф' : 'Выбрать тариф'
                    }
                    color={'#1DB6A0'}
                    onClick={() => modalIsOpen(setActiveModal)}
                />
            </div>

            {data.tarif_id ? (
                <>
                    <div className="tarif_title_wrapper">
                        <ImgLink
                            link={'assets/icon/icon_cross.svg'}
                            rightText={'Отменить подписку'}
                            color={'#E60C0C'}
                        />
                    </div>
                    <div className="tarif_description">
                        <ul>
                            {data.fixed_tarif_description ? (
                                data.fixed_tarif_description?.map(
                                    (e, index) => {
                                        return <li key={index}>{e}</li>
                                    }
                                )
                            ) : (
                                <div>ТАРИФ ПУСТОЙ</div>
                            )}
                        </ul>
                    </div>
                    <div className="btn_buy_wrapper">
                        <ImgLink
                            link={'assets/icon/icon_creditcard.svg'}
                            rightText={'Оплатить'}
                            color={'#1DB6A0'}
                        />
                    </div>
                </>
            ) : null}
        </div>
    )
}

export { TariffProfile }
