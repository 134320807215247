import { useReleaseDeleteMutation } from "./releaseListAPI"

export function useDeleteRelease() {


    const [deleteRelease, { isLoading }] = useReleaseDeleteMutation()
    
        

    const handlerDeleteRelease = async (UID) => {

    
        try {
            const delRelease = await deleteRelease({uid: UID}).unwrap()
            console.log(delRelease)
       
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }


    return {
        handlerDeleteRelease
    }

}