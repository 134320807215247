import { TextField } from '@mui/material'
import React from 'react'
import './InputFields.css'

const InputFields = ({...props}) => {
    return (
        <TextField
            style={{marginTop: 10, marginBottom: 10}}
            {...props}
            variant="outlined"
            fullWidth
        />
    )
}

export { InputFields }
