import React, { useState } from 'react'
import { useEffect } from 'react'
import { ChangeImage1 } from '../../../../components/ChangeImage1/ChangeImage1'
import { PreviewImage } from '../../../../components/PreviewImage/PreviewImage'
import { MainButton } from '../../../../UI/MainButton/MainButton'
import { useGetReleaseImage } from '../../api/useGetReleaseImage'
import { useReleaseAdd } from '../../api/useReleaseAdd'
import { useUpdateReleaseImage } from '../../api/useUpdateReleaseImage'

const AddReleaseStep1 = ({
    pageRelease,
    goNextPage,
    currentUID,
    setCurrentUID,
    setTypeRelease,
    setTrackList
}) => {
    console.log('currentUID', currentUID)

    const { image, handlerGetReleaseImage } = useGetReleaseImage()
    const { handlerReleaseAdd, isLoadingAddRelease, imageAdd } = useReleaseAdd(setCurrentUID)
    const { handlerUpdateReleaseImage, isLoadingUpdateImage } = useUpdateReleaseImage()

    const [releaseImage, setReleaseImage] = useState('')
    const [activeChangeImage, setActiveChangeImage] = useState(false)
    const [crop, setCrop] = useState({
        unit: '%',
        x: 0,
        y: 0,
        width: 50,
        height: 50,
    })

    useEffect(() => {
        console.log(image)

        if (currentUID != '') {
            handlerGetReleaseImage(currentUID)
            setReleaseImage(image)
        } else {
            setReleaseImage(imageAdd)
        }
    }, [currentUID, image, imageAdd])

    return (
        <div>
            <div className="add_release_all_title">Обложка релиза</div>
            {!activeChangeImage ? (
                <>
                    <PreviewImage
                        image={releaseImage}
                        setImage={setReleaseImage}
                        setActiveChangeImage={setActiveChangeImage}
                    />
                    {releaseImage ? (
                        <MainButton
                            color='yt_old_dark'
                            title="Далее"
                            onClick={() => {
                                goNextPage(2)
                            }}
                        />
                    ) : null}
                    <MainButton
                        color='yt_old_white'
                        title="Назад"
                        onClick={() => {
                            setTypeRelease('')
                            setTrackList([''])
                            setCurrentUID('')
                            goNextPage(0)
                        }}
                    />
                </>
            ) : (
                <ChangeImage1
                    crop={crop}
                    title="Загрузите изображение"
                    setCrop={setCrop}
                    image={releaseImage}
                    setActiveChangeImage={setActiveChangeImage}
                    handlerAdd={handlerReleaseAdd}
                    handlerUpdate={handlerUpdateReleaseImage}
                    currentUID={currentUID}
                    isLoadingAddRelease={isLoadingAddRelease}
                    isLoadingUpdateImage={isLoadingUpdateImage}
                />
            )}
        </div>
    )
}

export { AddReleaseStep1 }
