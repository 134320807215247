
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { TrackItem } from '../../../../components/TrackItem/TrackItem'
import { DoubleMassiveText } from '../../../../UI/DoubleMassiveText/DoubleMassiveText'
import { DoubleText } from '../../../../UI/DoubleText/DoubleText'
import { MainButton } from '../../../../UI/MainButton/MainButton'
import { useReleaseGetInfoMutation, useReleaseToPublishedMutation } from '../../api/releaseListAPI'
import { useGetReleaseImageMutation } from '../../api/releaseListAPI'
import { useGetTrackListMutation } from '../../api/releaseListAPI'
import './AddReleaseStep4.css'

const AddReleaseStep4 = ({
    currentUID,
    goNextPage,
    trackList,
    setTrackList,
    setTypeRelease,
    setCurrentUID
}) => {
    const [getListTrack] = useGetTrackListMutation()

    const [getReleaseImage, { isLoading: isLoadingReleaseImage }] =
        useGetReleaseImageMutation()

    const [toPublished, {isLoading:isLoadingPublished}] = useReleaseToPublishedMutation()

    const [getReleaseInfo, { isLoading }] = useReleaseGetInfoMutation()

    const [releaseImage, setReleaseImage] = useState('')
    const [releaseInfo, setReleaseInfo] = useState({
        album_year: '',
        card_apple: [''],
        card_spotify: [''],
        card_vk: [''],
        copyright: '',
        different_singers: false,
        genre: '',
        main_singers: [''],
        name: '',
        other_singers: [''],
        producer: '',
        soc_link_instagram: '',
        soc_link_vk: '',
        studio: '',
        type: '',
        upc: '',
        version: '',
    })

    const handlerPublished = async () => {

        let currentDATE = moment().format('YYYY-MM-DD')

        try {
            const request = await toPublished({ uid: currentUID, publicDate: currentDATE }).unwrap()

            console.log('request PUBLIC', request)


            setTrackList([''])
            setTypeRelease('')
            setCurrentUID('')
            goNextPage(0)

        } catch (e) {
            console.log('ERROR - ', e)
        }
    }

    const fetchTrackList = async () => {
        try {
            const request = await getListTrack({ uid: currentUID }).unwrap()
            setTrackList(request.result)
        } catch (e) {
            console.log('ERROR - ', e)
        }
    }

    const fetchImage = async () => {
        try {
            const request = await getReleaseImage({ uid: currentUID }).unwrap()
            setReleaseImage(request.result.ava_link)
        } catch (e) {
            console.log('ERROR - ', e)
        }
    }

    const fetchInfo = async () => {
        try {
            const request = await getReleaseInfo({ uid: currentUID }).unwrap()

            console.log('request: ', request)
            setReleaseInfo({
                album_year: request.result.album_year,
                card_apple: request.result.card_apple,
                card_spotify: request.result.card_spotify,
                card_vk: request.result.card_vk,
                copyright: request.result.copyright,
                different_singers: request.result.different_singers,
                genre: request.result.genre,
                main_singers: request.result.main_singers,
                name: request.result.name,
                other_singers: request.result.other_singers,
                producer: request.result.producer,
                soc_link_instagram: request.result.soc_link_instagram,
                soc_link_vk: request.result.soc_link_vk,
                studio: request.result.studio,
                type: request.result.type,
                upc: request.result.upc,
                version: request.result.version,
            })
        } catch (e) {
            console.log('ERROR - ', e)
        }
    }

    useEffect(() => {
        fetchTrackList()
    }, [])

    useEffect(() => {
        fetchImage()
        fetchInfo()
    }, [currentUID])

    const printDifferentSingers = () => {
        if (releaseInfo.different_singers) return 'Да'
        else return 'Нет'
    }

    const printType = () => {
        if (releaseInfo.type == 'album') return 'Альбом'
        else if (releaseInfo.type == 'single') return 'Сингл'
        else if (releaseInfo.type == 'clip') return 'Клип'
        else return 'Рингтон'
    }

    const [expanded, setExpanded] = useState(false)

    return (
        <>
            <div>Ваш релиз готов к отправке на модерацию!</div>
            <div>
                <img
                    src={releaseImage}
                    alt="release_image"
                    style={{ width: '100%' }}
                />
            </div>
            <div>
                <DoubleText
                    textLeft={'Название релиза:'}
                    textRight={releaseInfo.name}
                />
                <DoubleText textLeft={'Тип релиза:'} textRight={printType()} />
                <DoubleText
                    textLeft={'Версия/Подзаголовок:'}
                    textRight={releaseInfo.version}
                />
                <DoubleText
                    textLeft={'Продюсер:'}
                    textRight={releaseInfo.producer}
                />
                <DoubleText textLeft={'Жанр:'} textRight={releaseInfo.genre} />
                <DoubleText
                    textLeft={'Год записи:'}
                    textRight={releaseInfo.album_year}
                />
                <DoubleText
                    textLeft={'Авторское право:'}
                    textRight={releaseInfo.copyright}
                />
                <DoubleMassiveText
                    textLeft={'Основной исполнитель:'}
                    data={releaseInfo.main_singers}
                />
                <DoubleMassiveText
                    textLeft={'При участии:'}
                    data={releaseInfo.other_singers}
                />
                <DoubleText
                    textLeft={'Сборник:'}
                    textRight={printDifferentSingers()}
                />
            </div>
            <div>
                <div className="ars4_tracklist_title">Треклист</div>

                {trackList.map((el, index) => {
                    return (
                        <TrackItem
                            key={el.uid}
                            data={el}
                            index={index}
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                    )
                })}
            </div>
            <div>
                <MainButton
                    title="Отправить на модерацию"
                    color="yt_old_dark"
                    onClick={() => handlerPublished()}
                />
                <MainButton
                    title="Назад"
                    color="yt_old_white"
                    onClick={() => goNextPage(3)}
                />
            </div>
        </>
    )
}

export { AddReleaseStep4 }
