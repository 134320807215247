import React, { useState } from 'react'
import { AddReleaseStep1 } from '../modules/Release/screens/AddReleaseStep1/AddReleaseStep1'
import { ReleaseList } from '../modules/Release/screens/ReleaseList/ReleaseList'
import { ReleaseTariff } from '../modules/Release/screens/ReleaseTariff/ReleaseTariff'
import { useGetReleaseListQuery } from '../modules/Release/api/releaseListAPI'
import { AddReleaseStep2 } from '../modules/Release/screens/AddReleaseStep2/AddReleaseStep2'
import { AddReleaseStep3 } from '../modules/Release/screens/AddReleaseStep3/AddReleaseStep3'
import { AddReleaseStep4 } from '../modules/Release/screens/AddReleaseStep4/AddReleaseStep4'

const ReleaseListPage = () => {
    const { data = { result: [] } } = useGetReleaseListQuery()

    const [currentUID, setCurrentUID] = useState('')
    const [pageRelease, setPageRelease] = useState(0)
    const [trackList, setTrackList] = useState([''])
    const [typeRelease, setTypeRelease] = useState('')

    console.log('trackList ---------', trackList)
    console.log('type -------', typeRelease)
    console.log('currentUID', currentUID)

    const goNextPage = (numberPage) => {
        window.scrollTo(0,0)
        setPageRelease(numberPage)
    }

    return (
        <>
            {pageRelease == 0 ? (
                <>
                    <ReleaseTariff />
                    <ReleaseList
                        data={data.result}
                        setCurrentUID={setCurrentUID}
                        goNextPage={goNextPage}
                    />
                </>
            ) : null}
            {pageRelease == 1 ? (
                <AddReleaseStep1
                    currentUID={currentUID}
                    setCurrentUID={setCurrentUID}
                    setTypeRelease={setTypeRelease}
                    setTrackList={setTrackList}
                    pageRelease={pageRelease}
                    goNextPage={goNextPage}
                />
            ) : null}
            {pageRelease == 2 ? (
                <AddReleaseStep2
                    currentUID={currentUID}
                    goNextPage={goNextPage}
                    setTypeRelease={setTypeRelease}
                />
            ) : null}
            {pageRelease == 3 ? (
                <AddReleaseStep3
                    currentUID={currentUID}
                    goNextPage={goNextPage}
                    trackList={trackList}
                    setTrackList={setTrackList}
                    typeRelease={typeRelease}
                />
            ) : null}
            {pageRelease == 4 ? (
                <AddReleaseStep4
                    currentUID={currentUID}
                    goNextPage={goNextPage}
                    trackList={trackList}
                    setTrackList={setTrackList}
                    setCurrentUID={setCurrentUID}
                    setTypeRelease={setTypeRelease}
                />
            ) : null}
        </>
    )
}

export { ReleaseListPage }
