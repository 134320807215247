import { Button } from '@mui/material'
import React from 'react'
import './MainButton.css'

const MainButton = ({ title, ...props }) => {
    return (
        <Button
            style={{
                height: 48,
                fontSize: '1em',
                fontWeight: '600',
                borderRadius: 6,
                marginTop: 15,
                marginBottom: 15
            }}
            {...props}
            fullWidth
            variant="contained"
        >
            {title}
        </Button>
    )
}

export { MainButton }
