import React from 'react'
import { CheckBox } from '../../../../UI/CheckBox/CheckBox'
import { InputFields } from '../../../../UI/InputFields/InputFields'
import { MainButton } from '../../../../UI/MainButton/MainButton'

const Registration = () => {
    return (
        <div>
            <InputFields
                textLabel={'Логин'}
                placeholder={'Введите логин'}
                id={'reg_user_login'}
            />
            <InputFields
                textLabel={'E-mail'}
                placeholder={'Введите e-mail'}
                id={'reg_user_email'}
            />
            <InputFields
                textLabel={'Пароль'}
                placeholder={'Введите пароль'}
                id={'reg_user_pass'}
                type={'password'}
            />
            <InputFields
                textLabel={'Повторите пароль'}
                placeholder={'Повторите пароль'}
                id={'reg_user_check_pass'}
                type={'password'}
            />

            <CheckBox
                
                id={'reg_user_offer'}
                square={true}
                value={'user_offer'}
            
            >
               Согласен с условиями <a href="" style={{color: '#1DB6A0'}}>оферты</a>
            </CheckBox>
            <MainButton 
                title={'Зарегистрироваться'}
                className={'bg_dark'}
            
            />
        </div>
    )
}

export { Registration }
