import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'
import { centerCrop, makeAspectCrop } from 'react-image-crop'
import { MainButton } from '../../UI/MainButton/MainButton'
import 'react-image-crop/dist/ReactCrop.css'
import './ChangeImage1.css'
import {MainButtonLoad} from '../../UI/MainButtonLoad/MainButtonLoad'

const ChangeImage1 = ({
    image,
    title,
    crop,
    setCrop,
    setActiveChangeImage,
    handlerAdd,
    handlerUpdate,
    currentUID,
    isLoadingUpdateImage,
    isLoadingAddRelease
}) => {

    let img = new Image()
    const [imageOption, setImageOption] = useState({ width: '', height: '', img: '' })



    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        console.log('e.currentTarget', e.currentTarget)

        setImageOption({
            width: e.currentTarget.naturalWidth,
            height: e.currentTarget.naturalHeight,
            img: e.currentTarget
        })

        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                1 / 1,
                width,
                height
            ),
            width,
            height
        )

        setCrop(crop)
    }

    const sendAva = () => {
        let wpx = imageOption.width / 100,
            hpx = imageOption.height / 100,
            canvas = document.createElement('canvas'),
            coiff = 1,
            w = wpx * crop.width,
            h = hpx * crop.height

        if (w > 1440) {
            coiff = w / 1440

            canvas.width = 1440
            canvas.height = 1440
        } else {
            canvas.width = w
            canvas.height = h
        }

        canvas
            .getContext('2d')
            .drawImage(
                imageOption.img,
                (-1 * (wpx * crop.x)) / coiff,
                (-1 * (hpx * crop.y)) / coiff,
                imageOption.width / coiff,
                imageOption.height / coiff
            )

        console.log('canvas',canvas )

        let dataUrl = canvas.toDataURL('images/jpeg', 0.6)
        canvas.toBlob(function (blob) {

            if(currentUID == '') {
                handlerAdd(blob, () => setActiveChangeImage(false))
            } else {
                handlerUpdate(blob, currentUID, () => setActiveChangeImage(false))
            }
            
        })
    }


    return (
        <div className="change_image_loader">
            <div>{title}</div>
            <ReactCrop
                crop={crop}
                aspect={1 / 1}
                onChange={(crop, percentCrop) => {
                    setCrop(percentCrop)
                }}
            >
                <img src={image} onLoad={onImageLoad} />
            </ReactCrop>
            <MainButtonLoad
                title="Отправить"
                loading={isLoadingAddRelease || isLoadingUpdateImage}
                onClick={() => {
                    sendAva()
                }}
                color='yt_old_dark'
                
            />
        </div>
    )
}

export { ChangeImage1 }
