import React, { useState } from 'react'
import { useEffect } from 'react'
import { ChangeImage } from '../../../../components/ChangeImage/ChangeImage'
import './AvaProfile.css'

const AvaProfile = ({ avaLink }) => {
    const [activeChangeImg, setChangeImg] = useState(false)
    const [valueImg, setValueImg] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadImgTemp, setLoadImgTemp] = useState({ data1: null, data2: null })

    var fReader = new FileReader()

    const [crop, setCrop] = useState({
        unit: '%',
        x: 0,
        y: 0,
        width: 50,
        height: 50,
    })

    useEffect(() => {
        setLoading(true)
        if (loading === false) return

        setValueImg(loadImgTemp.data1)
        setChangeImg(true)
    }, [crop])

    useEffect(() => {
        if (loadImgTemp.data1 !== null && loadImgTemp.data2 !== null)
            checkImage()
    }, [loadImgTemp])

    let loadImgTempNew = { data1: null, data2: null }

    const loadImg = (data, type) => {
        loadImgTempNew['data' + type] = data

        if (loadImgTempNew.data1 !== null && loadImgTempNew.data2 !== null)
            setLoadImgTemp(loadImgTempNew)
    }

    const checkImage = () => {
        let obj = {
            unit: '%',
            x: 0,
            y: 0,
            width: 100,
            height: 100,
        }

        if (loadImgTemp.data2.width < loadImgTemp.data2.height) {
            obj.height =
                (100 / loadImgTemp.data2.height) * loadImgTemp.data2.width
            obj.y = (obj.width - obj.height) / 2
        } else {
            obj.width =
                (100 / loadImgTemp.data2.width) * loadImgTemp.data2.height
            obj.x = (obj.height - obj.width) / 2
        }

        setCrop(obj)
    }


    return (
        <>
            {!activeChangeImg ? (
                <div className={'profile_ava_wrapper'}>
                    <input
                        accept="image/*"
                        type="file"
                        className="preview_image_input"
                        onChange={(event) => {
                            fReader.readAsDataURL(event.target.files[0])

                            let img = new Image()
                            img.onload = function () {

                                console.log('img111', img)

                                loadImg(
                                    {
                                        width: this.width,
                                        height: this.height,
                                        path: event.target.value,
                                        file: event.target.files[0],
                                        img: img,
                                    },
                                    '2'
                                )
                            }
                            img.src = window.URL.createObjectURL(
                                event.target.files[0]
                            )

                            fReader.onloadend = function (event) {
                                loadImg(event.target.result, '1')
                                //setValueImg()
                                //setChangeImg(true)
                            }
                        }}
                    />
                    <img src={avaLink} />
                </div>
            ) : (
                <ChangeImage
                    crop={crop}
                    setCrop={setCrop}
                    src={valueImg ? valueImg : avaLink}
                    title={'Изменить аватарку профиля'}
                    loadImg={loadImgTemp.data2}
                />
            )}
        </>
    )
}

export { AvaProfile }
