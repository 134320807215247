import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save';

const MainButtonLoad = ({ title, children, ...props }) => {
    return (
        <LoadingButton
            {...props}
            style={{
                height: 48,
                fontSize: '1em',
                fontWeight: '600',
                borderRadius: 6,
                marginTop: 15,
                marginBottom: 15,
            }}
            variant="outlined"
            loadingPosition="start"
            startIcon={<SaveIcon />}
            fullWidth
        >
            {title}{children}
        </LoadingButton>
    )
}

export { MainButtonLoad }
