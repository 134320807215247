import './App.css'
import { useRouter } from './components/hooks/useRouter'


function App() {

    const {Routers} = useRouter()

    return (
        <>
            {Routers()}
        </>
    )
}

export default App
