import React, { useState } from 'react'
import { useUpdateProfileSocial } from '../../modules/Profile/api/useUpdateProfileSocial'
import { InputFields } from '../../UI/InputFields/InputFields'
import { DoubleButton } from '../DoubleButton/DoubleButton'

const UserSocialChange = ({ data, changeEdit }) => {

    const {handlerUpdateSocial} = useUpdateProfileSocial()

    const [userSocial, setUserSocial] = useState({
        soc_facebook: data.soc_facebook,
        soc_instagram: data.soc_instagram,
        soc_telegram: data.soc_telegram,
        soc_vk: data.soc_vk,
    })

    

    return (
        <div className="profile_change_block">
            <InputFields
                textLabel={'Vk'}
                id={'profile_link_vk'}
                defaultValue={data.soc_vk}
                onChange={(event) =>
                    setUserSocial({ ...userSocial, soc_vk: event.target.value })
                }
            />
            <InputFields
                textLabel={'Номер телефона телеграм аккаунта'}
                id={'profile_link_telegram'}
                defaultValue={data.soc_telegram}
                onChange={(event) =>
                    setUserSocial({ ...userSocial, soc_telegram: event.target.value })
                }
            />
            <InputFields
                textLabel={'Instagram'}
                id={'profile_link_instagram'}
                defaultValue={data.soc_instagram}
                onChange={(event) =>
                    setUserSocial({ ...userSocial, soc_instagram: event.target.value })
                }
            />
                <InputFields
                textLabel={'Facebook'}
                id={'profile_link_facebook'}
                defaultValue={data.soc_facebook}
                onChange={(event) =>
                    setUserSocial({ ...userSocial, soc_facebook: event.target.value })
                }
            />
            <DoubleButton
                leftTitle={'Отмена'}
                leftClassName={'bg_white'}
                rightTitle={'Сохранить'}
                rightClassName={'bg_dark'}
                leftOnClick={() => changeEdit(true, true, true)}
                rightOnClick={() => {
                    console.log(userSocial)
                    handlerUpdateSocial(userSocial)}}
            />
        </div>
    )
}

export { UserSocialChange }
