import React from 'react'
import { DoubleText } from '../../UI/DoubleText/DoubleText'
import './UserSocialVisible.css'

const UserSocialVisible = ({data, changeEdit}) => {
    return (
        <div className='user_block_border_bottom'>
            <div className="pencil_wrapper">
                <DoubleText textLeft={'Социальные сети:'} />
                <div className="pencil" onClick={() => changeEdit(true, true, false)}>
                    <img src="assets/icon/icon_pencil.svg" alt="" />
                </div>
            </div>
            <div className="social_wrapper">
                <ul>
                    {data.soc_vk ? (
                        <li>
                            <a rel="noreferrer" href={data.soc_vk}>
                                <img src="assets/icon/icon_vk.svg" alt="VK" />
                            </a>
                        </li>
                    ) : null}
                    {data.soc_telegram ? (
                        <li>
                            <a rel="noreferrer" href={data.soc_telegram}>
                                <img
                                    src="assets/icon/icon_telegram.svg"
                                    alt="TG"
                                />
                            </a>
                        </li>
                    ) : null}
                    {data.soc_instagram ? (
                        <li>
                            <a rel="noreferrer" href={data.soc_instagram}>
                                <img
                                    src="assets/icon/icon_instagram.svg"
                                    alt="Instagram"
                                />
                            </a>
                        </li>
                    ) : null}
                    {data.soc_facebook ? (
                        <li>
                            <a rel="noreferrer" href={data.soc_facebook}>
                                <img
                                    src="assets/icon/icon_facebook.svg"
                                    alt="Facebook"
                                />
                            </a>
                        </li>
                    ) : null}
                </ul>
            </div>
        </div>
    )
}

export { UserSocialVisible }
