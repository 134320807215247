import React from 'react'
import { MainButton } from '../../UI/MainButton/MainButton'
import './DoubleButton.css'

const DoubleButton = ({
    leftTitle,
    rightTitle,
    leftClassName,
    rightClassName,
    leftOnClick,
    rightOnClick
}) => {
    return (
        <div className="double_button_block">
            <MainButton title={leftTitle} className={leftClassName} onClick={leftOnClick}/>
            <MainButton title={rightTitle} className={rightClassName} onClick={rightOnClick}/>
        </div>
    )
}

export { DoubleButton }
