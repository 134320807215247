import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import moment from 'moment'

export const statReleaseStoreAPI = createApi({
    reducerPath: 'statReleaseStoreAPI',
    tagTypes: ['Stat'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.yourtunes.net/api/v2/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                // headers.set('authorization', `Bearer ${token}`)
                headers.set(
                    'authorization',
                    `Bearer 6321dfb66102djpjtf995hq1bqwemy99qy3hdncramq`
                )
            }
            return headers
        },
    }),
    endpoints: (build) => ({
        releaseStores: build.query({
            query: () => `statistic/release_stores`,
            transformResponse(data, meta, arg) {
                for (let i = 0; i < data.result.length; i++) {
                    data.result[i].name = data.result[i].platform
                    data.result[i].y = Number(data.result[i].stream)
                }
                return data
            },
        }),
    }),
})

export const {
    useReleaseStoresQuery,
} = statReleaseStoreAPI
