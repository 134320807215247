import React from 'react'
import './MenuSwitcher.css'

const MenuSwitcher = ({ nameLeft, nameRight, menuStatus }) => {
    



    return (
        <div className="menu_switcher_wrapper">
            <ul>
                <li className={menuStatus.menuActive ? 'active' : ''} onClick={() => menuStatus.setMenuActive(true)}>{nameLeft}</li>
                <li className={!menuStatus.menuActive ? 'active' : ''} onClick={() => menuStatus.setMenuActive(false)}>{nameRight}</li>
            </ul>
        </div>
    )
}

export { MenuSwitcher }
