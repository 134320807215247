import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { store, persistor } from './store/index'
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        secondary: {
          main: '#11cb5f',
        },
        yt_old_dark: {
            main: '#1F1D1E',
            contrastText: '#fff'
        },
        yt_old_green: {
            main: '#1DB6A0',
            contrastText: '#fff'
        },
        yt_old_white: {
            main: '#FFFFFF',
            contrastText: '#1F1D1E'
        }
      },
})


ReactDOM.render(
    <BrowserRouter>
         <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
)
