import React from 'react'
import { DoubleText } from '../../UI/DoubleText/DoubleText'

const UserPasswordVisible = ({changeEdit}) => {
    return (
        <div className="pencil_wrapper user_info_item">
            <DoubleText textLeft={'Пароль:'} textRight={'**********'} />
            <div className="pencil" onClick={() => changeEdit(true, false, true)}>
                <img src="assets/icon/icon_pencil.svg" alt="" />
            </div>
        </div>
    )
}

export { UserPasswordVisible }
