import React from 'react'
import { RadioBox } from '../../UI/RadioBox/RadioBox'

const MultipleRadio = ({ data, radioValue, setRadioValue }) => {
    return (
        <div
            onChange={(e) => {
                setRadioValue(e.target.value)
            }}
        >
            {data.map((e, index) => {
                return (
                    <RadioBox
                        key={index}
                        index={index}
                        radioValue={radioValue}
                        data={e}
                    />
                )
            })}
        </div>
    )
}

export { MultipleRadio }
