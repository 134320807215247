import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useRouter } from '../hooks/useRouter'
import './HomeLayout.css'

const HomeLayout = () => {
    const isAuth = useSelector((state) => state.auth.isAuth)
    const token_user = useSelector((state) => state.auth.token)
    const { MenuNoAuth } = useRouter()

    if (isAuth) {
        return <Navigate to="/profile" />
    }

    return (
        <div className="bg_no_auth">
            <div className="container_no_auth">
                <img src="/assets/images/yt_logo.png" alt="LOGO" className='no_auth_logo'/>
                <div className="wrapper_no_auth">
                    <nav>{MenuNoAuth()}</nav>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export { HomeLayout }
