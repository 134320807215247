import React, { useState } from 'react'
import './CheckBox.css'

const CheckBox = ({ id, children, square, circle, ...props }) => {
    const [checked, setChecked] = useState(false)

    return (
        <div className="mde_checkbox_wrapper">
            {square ? (
                <>
                    {!checked ? (
                        <img
                            src="assets/icon/icon_checkbox_square_note_checked.svg"
                            className="mde_checkbox_img_square"
                        />
                    ) : (
                        <img
                            src="assets/icon/icon_checkbox_square_checked.svg"
                            className="mde_checkbox_img_square"
                        />
                    )}
                </>
            ) : null}
            {circle ? (
                <>
                    {!checked ? (
                        <img
                            src="assets/icon/icon_checkbox_circle_note_checked.svg"
                            className="mde_checkbox_img_circle"
                        />
                    ) : (
                        <img
                            src="assets/icon/icon_checkbox_circle_checked.svg"
                            className="mde_checkbox_img_circle"
                        />
                    )}
                </>
            ) : null}
            <label htmlFor={id} className={circle ? 'mde_checkbox_label_circle' : 'mde_checkbox_label_square'}>
                {children}
            </label>
            <input
                type="checkbox"
                id={id}
                className={'mde_checkbox_input'}
                onChange={(event) => {
                    setChecked(event.target.checked)
                }}
                {...props}
            />
        </div>
    )
}

export { CheckBox }
