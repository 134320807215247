import React from 'react'
import './PreviewImage.css'



const PreviewImage = ({ image, setImage, setActiveChangeImage }) => {

    var fReader = new FileReader()
    let screen_width = document.documentElement.clientWidth - 40

    return (
        <div 
        className="preview_img_wrapper" 
        style={{ width: screen_width, height: screen_width}}
        >
            <input
                accept="image/*"
                type="file"
                className="preview_image_input"
                onChange={(event) => {
                    fReader.readAsDataURL(event.target.files[0])
                    fReader.onloadend = function (event) {
                        setImage(event.target.result)
                        setActiveChangeImage(true)
                    }

                }}
            />
            <img 
                src={image ? image : 'assets/icon/icon_no_release_image.svg'} 
                style={image ? {} : {width: 94, height: 94}}
            />
        </div>
    )
}

export { PreviewImage }
