import React from 'react'
import { MainButton } from '../../../UI/MainButton/MainButton'
import { Modal } from '../../../UI/Modal/Modal'


const ModalBoolean = ({activeModal, setActiveModal, children, clickYes }) => {

    return (
        <>
            <Modal close={setActiveModal} active={activeModal}>
                <div className='modal_content'>
                    {children}
                </div>
                <div className="modal_boolean_btn_wrapper">
                    <MainButton className="bg_dark" title="Да" onClick={clickYes}/>
                    <MainButton className="bg_white" title="Нет" onClick={() => setActiveModal(false)}/>
                </div>
            </Modal>
        </>
    )
}

export { ModalBoolean }
