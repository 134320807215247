import React, { useState } from 'react'
import { useGetUserTokenMutation } from '../api/userAPI'
import { useDispatch } from 'react-redux'
import { getToken } from '../../../store/authSlice'

import { InputFields } from '../../../UI/InputFields/InputFields'
import { MainButton } from '../../../UI/MainButton/MainButton'
import { Loading } from '../../../UI/Loading/Loading'

const Auth = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [errorLoginMess, setErrorLoginMess] = useState('')
    const [errorPassMess, setErrorPassMess] = useState('')
    const [errorFail, setErrorFail] = useState('')

    const dispatch = useDispatch()

    const [authUser, { isLoading, error, isError }] = useGetUserTokenMutation()

    const authHandler = async () => {
        if (login.trim() === '') {
            setErrorLoginMess('Введите имя пользователя')
            console.log('Введите имя пользователя')
        } else if (password.trim() === '') {
            setErrorPassMess('Введите пароль')
            console.log('Введите пароль')
        } else {
            try {
                const tokenUser = await authUser({
                    login: login,
                    password: password,
                }).unwrap()
                dispatch(getToken(tokenUser))
                setLogin('')
                setPassword('')
            } catch (e) {
                console.log('s3_error ', e)
                setErrorFail(e.data.message)
            }
        }
    }

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <InputFields
                        textLabel={'Логин'}
                        type={'text'}
                        id={'user_login'}
                        onChange={(event) => setLogin(event.target.value)}
                    />
                    <InputFields
                        textLabel={'Пароль'}
                        type={'password'}
                        id={'user_pass'}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <MainButton
                        color='yt_old_dark'
                        onClick={() => {
                            console.log('login', login, 'password', password)
                            authHandler()
                        }}
                        title={'Войти'}
                    />
                </>
            )}
        </>
    )
}

export { Auth }
