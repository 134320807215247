import React, { useEffect, useState } from 'react'
import { useGetTrackListMutation } from '../../api/releaseListAPI'
import { TrackInfo } from '../TrackInfo/TrackInfo'
import { TrackList } from '../TrackList/TrackList'

const AddReleaseStep3 = ({
    currentUID,
    goNextPage,
    trackList,
    setTrackList,
    typeRelease,
}) => {
    const [getTrackList, { isLoading: isLoadingTrackList }] =
        useGetTrackListMutation()

    const [activeTrackListBlock, setActiveTrackListBlock] = useState(true)
    const [currentTrackUID, setCurrentTrackUID] = useState('')

    console.log('UID текущего трека', currentTrackUID)

    const typeCheck = () => {
        if (typeRelease == 'album') return true
        else if (typeRelease == 'single') return true
        else {
            setCurrentTrackUID(trackList[0].uid)
            return false
        }
    }

    useEffect(() => {
        fetchTrackList()
    }, [])

    useEffect(() => {
        setActiveTrackListBlock(typeCheck())
    }, [trackList])

    const fetchTrackList = async () => {
        try {
            const RequestTrackList = await getTrackList({
                uid: currentUID,
            }).unwrap()

            setTrackList(RequestTrackList.result)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }

    return (
        <>
            {activeTrackListBlock ? (
                <TrackList
                    goNextPage={goNextPage}
                    typeRelease={typeRelease}
                    trackList={trackList}
                    setTrackList={setTrackList}
                    setCurrentTrackUID={setCurrentTrackUID}
                    currentTrackUID={currentTrackUID}
                    currentUID={currentUID}
                    setActiveTrackListBlock={setActiveTrackListBlock}
                />
            ) : (
                <TrackInfo
                    currentTrackUID={currentTrackUID}
                    setCurrentTrackUID={setCurrentTrackUID}
                    goNextPage={goNextPage}
                    setActiveTrackListBlock={setActiveTrackListBlock}
                />
            )}
        </>
    )
}

export { AddReleaseStep3 }
