import React from 'react'
import './DoubleText.css'

const DoubleText = ({ textLeft, textRight }) => {
    return (
        <div className="double_text_wrapper">
            <span className='left_text'>{textLeft}</span>
            <span>{textRight ? textRight : null}</span>
        </div>
    )
}

export { DoubleText }
