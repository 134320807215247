import { useGetUserAvaUpdateMutation } from "./profileAPI"

export function useUpdateProfileAva() {


    const [updateAva, { isLoading }] =
        useGetUserAvaUpdateMutation()
        

    const handlerUpdateAva = async (urlImage) => {

        

        let formData = new FormData()

        formData.append('input_ava', urlImage, 'filename.png')

        console.log('formData', formData, 'urlImage', urlImage)

        try {
            const newAva = await updateAva(formData).unwrap()
            console.log(newAva)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }


    return {
        handlerUpdateAva,
    }

}