import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

export const balanceAPI = createApi({
    reducerPath: 'balanceAPI',
    tagTypes: ['User'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.yourtunes.net/api/v2/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
                // headers.set(
                //     'authorization',
                //     `Bearer 6321dfb66102djpjtf995hq1bqwemy99qy3hdncramq`
                // )
            }
            return headers
        },
    }),
    endpoints: (build) => ({
        getUserBalance: build.query({
            query: () => `/balance/get`,
        }),
        getUserBalanceList: build.query({
            query: () => `/balance/list`,
            transformResponse(data) {

                for (let i = 0; i < data.result.length; i ++) {
                    if(data.result[i].type == 1) {
                        data.result[i].print_type = 'Списание'
                        data.result[i].class_name = 'write_off'
                    } else {
                        data.result[i].print_type = 'Поступление'
                        data.result[i].class_name = 'admission'
                    }   
    
                    data.result[i].print_time = moment(data.result[i].date).format('MMMM YYYY')
                }

                return data
            },
        }),
    }),
})

export const {
    useGetUserBalanceQuery,
    useGetUserBalanceListQuery
} = balanceAPI
