import React, { useState } from 'react'
import { BalanceItem } from '../../../../components/BalanceItem/BalanceItem'
import { MenuSwitcher } from '../../../../components/MenuSwitcher/MenuSwitcher'
import { ImgLink } from '../../../../UI/ImgLink/ImgLink'
import './BalanceList.css'

const BalanceList = ({ data }) => {
    const [menuActive, setMenuActive] = useState(true)


    return (
        <>
            <MenuSwitcher
                nameLeft={'Авто'}
                nameRight={'Созданные'}
                menuStatus={{ menuActive, setMenuActive }}
            />
            {menuActive ? (
                <div>
                    {data.map((e, index) => {
                        return (
                            <BalanceItem data={e} index={index} key={e.uid} />
                        )
                    })}
                </div>
            ) : (
                <div className='btn_create_report'>
                    <ImgLink
                        link={'assets/icon/icon_circle_plus.svg'} 
                        rightText={'Создать отчет'} 
                        color={'#1DB6A0'}
                    />
                </div>
            )}
        </>
    )
}

export { BalanceList }
