import React from 'react'
import { MainButton } from '../../../../UI/MainButton/MainButton'
import './ReleaseList.css'
import { ReleaseItem } from '../../../../components/ReleaseItem/ReleaseItem'

const ReleaseList = ({ data, goNextPage, setCurrentUID }) => {
    return (
        <>
            <MainButton
                title={'Добавить релиз'}
                color="yt_old_dark"
                onClick={() => {
                    setCurrentUID('')
                    goNextPage(1)
                }}
            />
            <div className="release_list">
                {data.map((e) => {
                    return (
                        <ReleaseItem
                            key={e.uid}
                            data={e}
                            setCurrentUID={setCurrentUID}
                            goNextPage={goNextPage}
                        />
                    )
                })}
            </div>
        </>
    )
}

export { ReleaseList }
