import React, { useEffect, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CircularProgress from '@mui/material/CircularProgress'
import {
    useGetTrackListMutation,
    useTrackDeleteMutation,
} from '../../modules/Release/api/releaseListAPI'

const TrackListItem = ({
    track,
    index,
    setCurrentTrackUID,
    setActiveTrackListBlock,
    currentUID,
    setTrackList,
}) => {
    const [trackDelete, { isLoading: isLoadingTrackDelete }] =
        useTrackDeleteMutation()

    const handlerTrackDelete = async () => {
        try {
            const request = await trackDelete({ uid: track.uid }).unwrap()
            console.log('request delete track', request)
            fetchTrackList()
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }

    const [getTrackList, { isLoading: isLoadingTrackList }] =
        useGetTrackListMutation()

    const fetchTrackList = async () => {
        try {
            const RequestTrackList = await getTrackList({
                uid: currentUID,
            }).unwrap()

            setTrackList(RequestTrackList.result)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }

    const [printName, setPrintName] = useState('')

    const printTrackName = (name) => {
        if (name == null || name == '') setPrintName('Черновик')
        else setPrintName(name) 
    }

    useEffect(() => {
        printTrackName(track.name)
    },[track.name])

    return (
        <div
            className="ars4__tracklist_item"
            onClick={() => {
                setCurrentTrackUID(track.uid)
                setActiveTrackListBlock(false)
            }}
        >
            {index + 1}. {printName}
            <div
                className="ars4_tracklist_item__delete"
                onClick={(e) => {
                    e.stopPropagation()
                    handlerTrackDelete()
                }}
            >
                {index === 0 ? null : (
                    <>
                        {!isLoadingTrackDelete ? (
                            <DeleteForeverIcon />
                        ) : (
                            <CircularProgress />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export { TrackListItem }
