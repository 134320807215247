import React from 'react'
import './DoubleMassiveText.css'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'

const DoubleMassiveText = ({ textLeft, data }) => {
    return (
        <div className="double_text_wrapper">
            <span className="left_text">{textLeft}</span>
            <ul>
                {data.map((el, index) => {
                    return (
                        <li key={index}>
                            {index != 0 ? (
                                
                                <SubdirectoryArrowRightIcon
                                    style={{ fontSize: 12, color: '#949494' }}
                                />
                                
                            ) : null}
                            {el}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export { DoubleMassiveText }
