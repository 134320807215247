export function useModal() {


    const modalIsOpen = (setActiveModal) => {
        document.body.style.overflow = 'hidden'
        setActiveModal(true)
    }

    const modalIsClose = (setActiveModal) => {
        document.body.style.overflow = 'scroll'
        setActiveModal(false)
    }

    return {
        modalIsClose,
        modalIsOpen,
    }
}
