import React, { useEffect, useState } from 'react'
import { useReleaseStoresQuery } from '../../api/statReleaseStoreAPI'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { StatWindow } from '../../../../components/StatWindow/StatWindow'

const Platforms = () => {
    const [chartOptions, setChartOptions] = useState({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
        },
        // title: {
        //     text: 'Browser market shares in May, 2020',
        //     align: 'left',
        // },
        // tooltip: {
        //     pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        // },
        // accessibility: {
        //     point: {
        //         valueSuffix: '%',
        //     },
        // },
        // plotOptions: {
        //     pie: {
        //         allowPointSelect: true,
        //         cursor: 'pointer',
        //         dataLabels: {
        //             enabled: true,
        //             format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        //         },
        //     },
        // },
        series: [
            {
                name: 'Brands',
                innerSize: '80%',
                colorByPoint: true,
                data: [],
            },
        ],
    })

    const { data = { result: [] }, isLoading } = useReleaseStoresQuery()

    const updateDATA = () => {
        setChartOptions({
            series: [{ data: data.result }],
        })
    }

    useEffect(() => {
        if (data.result != undefined) updateDATA()
    }, [isLoading])

    return (
        <StatWindow>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </StatWindow>
    )
}

export { Platforms }
