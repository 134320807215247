import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useReleaseGetInfoMutation } from '../../api/releaseListAPI'
import Typography from '@mui/material/Typography'
import moment from 'moment/moment'
import Slider from '@mui/material/Slider'
import { MultipleTextInput } from '../../../../components/MultipleTextInput/MultipleTextInput'
import './AddReleaseStep2.css'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import { useReleaseUpdateInfo } from '../../api/useReleaseUpdateInfo'
import { useDebounce } from '../../../../components/hooks/useDebounce'
import { MainButton } from '../../../../UI/MainButton/MainButton'
import { InputFields } from '../../../../UI/InputFields/InputFields'
import { YtRadioBoolean } from '../../../../UI/YtRadioBoolean/YtRadioBoolean'
import { YtSlider } from '../../../../UI/YtSlider/YtSlider'
import { YtSelect } from '../../../../UI/YtSelect/YtSelect'

const AddReleaseStep2 = ({ goNextPage, currentUID, setTypeRelease }) => {
    // const { handlerReleaseGetInfo } = useReleaseGetInfo()

    const currentDATE = Number(moment().format('YYYY'))

    const [releaseData, setReleaseData] = useState({
        name: '',
        upc: '',
        type: '',
        version: '',
        producer: '',
        album_year: currentDATE,
        genre: '',
        copyright: '',
        different_singers: false,
        soc_link_vk: '',
        soc_link_instagram: '',
    })

    const [mainSingers, setMainSingers] = useState([''])
    const [otherSingers, setOtherSingers] = useState([''])
    const [cardApple, setCardApple] = useState([''])
    const [cardSpotify, setCardSpotify] = useState([''])
    const [cardVk, setCardVk] = useState([''])

    const [requireFields, setRequireFields] = useState({
        r_name: false,
        r_name_dirty: false,
        r_producer: false,
        r_producer_dirty: false,
        // r_genre: false,
        // r_podgenre: false,
        r_copyright: false,
        r_copyright_dirty: false,
        r_main_singers: false,
        r_main_singers_dirty: false,
        r_soc_link_vk: false,
        r_soc_link_vk_dirty: false,
        r_soc_link_instagram: false,
        r_soc_link_instagram_dirty: false,
    })

    const onBlurDirty = (e) => {
        let newArg = { ...requireFields }
        newArg[`r_${e.target.name}_dirty`] = true
        setRequireFields(newArg)
    }

    useEffect(() => {
        let newArg = { ...requireFields }

        if (releaseData.name != '') newArg.r_name = true
        else newArg.r_name = false

        if (releaseData.producer != '') newArg.r_producer = true
        else newArg.r_producer = false

        if (releaseData.copyright != '') newArg.r_copyright = true
        else newArg.r_copyright = false

        if (mainSingers[0] != '') newArg.r_main_singers = true
        else newArg.r_main_singers = false

        if (
            releaseData.soc_link_vk != '' ||
            releaseData.soc_link_instagram != ''
        ) {
            newArg.r_soc_link_vk = true
            newArg.r_soc_link_instagram = true
        } else {
            newArg.r_soc_link_vk = false
            newArg.r_soc_link_instagram = false
        }

        setRequireFields(newArg)
    }, [
        releaseData.name,
        releaseData.producer,
        releaseData.copyright,
        mainSingers[0],
        releaseData.soc_link_vk,
        releaseData.soc_link_instagram,
    ])

    useEffect(() => {
        if (
            requireFields.r_producer &&
            requireFields.r_name &&
            requireFields.r_copyright &&
            requireFields.r_main_singers &&
            requireFields.r_soc_link_vk &&
            requireFields.r_soc_link_instagram
        )
            setActiveBtn(true)
        else setActiveBtn(false)
    }, [
        requireFields.r_name,
        requireFields.r_producer,
        requireFields.r_copyright,
        requireFields.r_main_singers,
        requireFields.r_soc_link_vk,
        requireFields.r_soc_link_instagram,
    ])

    const [releaseGetInfo, { isLoading }] = useReleaseGetInfoMutation()
    const { handlerUpdateInfo } = useReleaseUpdateInfo()

    const debouncedUpdate = useDebounce(handlerUpdateInfo, 500)

    const handlerReleaseGetInfo = async (UID) => {
        try {
            const releaseInfo = await releaseGetInfo({ uid: UID }).unwrap()

            let newArg = { ...releaseData }

            if (releaseInfo.result.name == '') newArg.name = 'Черновик'
            else newArg.name = releaseInfo.result.name

            if (releaseInfo.result.upc == null) newArg.upc = ''
            else newArg.upc = releaseInfo.result.upc

            newArg.type = releaseInfo.result.type

            if (releaseInfo.result.version == null) newArg.version = ''
            else newArg.version = releaseInfo.result.version

            if (releaseInfo.result.producer == null) newArg.producer = ''
            else newArg.producer = releaseInfo.result.producer

            if (releaseInfo.result.copyright == null) newArg.copyright = ''
            else newArg.copyright = releaseInfo.result.copyright

            if (releaseInfo.result.soc_link_vk == null) newArg.soc_link_vk = ''
            else newArg.soc_link_vk = releaseInfo.result.soc_link_vk

            if (releaseInfo.result.soc_link_instagram == null)
                newArg.soc_link_instagram = ''
            else
                newArg.soc_link_instagram =
                    releaseInfo.result.soc_link_instagram

            if (releaseInfo.result.genre == null) newArg.genre = ''
            else newArg.genre = releaseInfo.result.genre

            if (releaseInfo.result.album_year == null)
                newArg.album_year = currentDATE
            else newArg.album_year = releaseInfo.result.album_year

            if (releaseInfo.result.different_singers == null)
                newArg.different_singers = false
            else newArg.different_singers = releaseInfo.result.different_singers

            setReleaseData(newArg)

            setMainSingers(releaseInfo.result.main_singers)
            setOtherSingers(releaseInfo.result.other_singers)
            setCardApple(releaseInfo.result.card_apple)
            setCardSpotify(releaseInfo.result.card_spotify)
            setCardVk(releaseInfo.result.card_vk)
            setTypeRelease(releaseInfo.result.type)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }

    const [activeBtn, setActiveBtn] = useState(false)

    useEffect(() => {
        handlerReleaseGetInfo(currentUID)
    }, [])

    const marks = [
        {
            value: 2010,
            label: '2010',
        },
        {
            value: currentDATE,
            label: currentDATE,
        },
    ]

    const onChangeUpdate = (e) => {
        let newArg = { ...releaseData },
            sendDATA = { uid: currentUID }

        sendDATA[e.target.name] = e.target.value
        newArg[e.target.name] = e.target.value

        setReleaseData(newArg)
        debouncedUpdate(sendDATA)
    }

    const onChangeUpdateRadio = (e) => {
        let newArg = { ...releaseData },
            sendDATA = { uid: currentUID }

        if (e.target.value == 'true') {
            sendDATA[e.target.name] = true
            newArg[e.target.name] = true
        } else {
            sendDATA[e.target.name] = false
            newArg[e.target.name] = false
        }

        setReleaseData(newArg)
        debouncedUpdate(sendDATA)
    }

    const onChangeSlider = (e, newValue) => {
        let newArg = { ...releaseData },
            sendDATA = { uid: currentUID }

        sendDATA[e.target.name] = newValue
        newArg[e.target.name] = newValue

        setReleaseData(newArg)
        debouncedUpdate(sendDATA)
    }


    const type_release = [
        // {label: 'Выберите тип записи', value: null},
        {label: 'Альбом', value: 'album'},
        {label: 'Сингл', value: 'single'},
        {label: 'Рингтон', value: 'ringtone'},
        {label: 'Клип', value: 'clip'},
    ]



    return (
        <>
            <InputFields
                label="Название релиза"
                value={releaseData.name}
                onChange={onChangeUpdate}
                name="name"
                error={
                    requireFields.r_name_dirty && !requireFields.r_name
                        ? true
                        : false
                }
                helperText={
                    requireFields.r_name_dirty && !requireFields.r_name
                        ? 'Поле должно быть заполненным'
                        : ''
                }
                onBlur={onBlurDirty}
                required
            />
            <InputFields
                label="UPC / EAN"
                value={releaseData.upc}
                name="upc"
                onChange={onChangeUpdate}
            />
            <YtSelect
                id='release_type'
                idLabel='release_type_label'
                name="type"
                value={releaseData.type}
                label="Тип релиза"
                onChange={(e) => {
                    setTypeRelease(e.target.value)
                    onChangeUpdate(e)
                }}
                data={type_release}
            />
            <InputFields
                label="Версия/Подзаголовок"
                value={releaseData.version}
                onChange={onChangeUpdate}
                name="version"
            />
            <InputFields
                label="Выпускающий лейбл"
                value={releaseData.producer}
                onChange={onChangeUpdate}
                name="producer"
                error={
                    requireFields.r_producer_dirty && !requireFields.r_producer
                        ? true
                        : false
                }
                helperText={
                    requireFields.r_producer_dirty && !requireFields.r_producer
                        ? 'Поле должно быть заполненным'
                        : ''
                }
                onBlur={onBlurDirty}
                required
            />
            <YtSlider
                textLabel={`Год записи ${releaseData.album_year}`}
                min={2010}
                step={1}
                max={currentDATE}
                value={Number(releaseData.album_year)}
                marks={marks}
                onChange={onChangeSlider}
                name='album_year'
            />
            <InputFields
                label="Авторское право"
                value={releaseData.copyright}
                onChange={onChangeUpdate}
                name="copyright"
                error={
                    requireFields.r_copyright_dirty &&
                    !requireFields.r_copyright
                        ? true
                        : false
                }
                helperText={
                    requireFields.r_copyright_dirty &&
                    !requireFields.r_copyright
                        ? 'Поле должно быть заполненным'
                        : ''
                }
                onBlur={onBlurDirty}
                required
            />
            <MultipleTextInput
                textLabel="Основной исполнитель"
                mainArg={mainSingers}
                setArg={setMainSingers}
                UID={currentUID}
                debounced={debouncedUpdate}
                name="main_singers"
                requireFields={requireFields}
                setRequireFields={setRequireFields}
            />
            <MultipleTextInput
                textLabel="При участии"
                mainArg={otherSingers}
                setArg={setOtherSingers}
                UID={currentUID}
                debounced={debouncedUpdate}
                name="other_singers"
            />
            <YtRadioBoolean
                textLabel="Ваш релиз является сборником, где собраны треки разных
                исполнителей?"
                onChange={onChangeUpdateRadio}
                name="different_singers"
                value={releaseData.different_singers}
            />
            <MultipleTextInput
                textLabel="Apple Music"
                mainArg={cardApple}
                setArg={setCardApple}
                UID={currentUID}
                name="card_apple"
                debounced={debouncedUpdate}
            />
            <MultipleTextInput
                textLabel="Spotify"
                mainArg={cardSpotify}
                setArg={setCardSpotify}
                UID={currentUID}
                name="card_spotify"
                debounced={debouncedUpdate}
            />
            <MultipleTextInput
                textLabel="VK / BOOM"
                mainArg={cardVk}
                setArg={setCardVk}
                UID={currentUID}
                name="card_vk"
                debounced={debouncedUpdate}
            />
            <InputFields
                label="Страница ВКонтакте"
                value={releaseData.soc_link_vk}
                onChange={onChangeUpdate}
                name="soc_link_vk"
                error={
                    requireFields.r_soc_link_vk_dirty &&
                    !requireFields.r_soc_link_vk
                        ? true
                        : false
                }
                helperText={
                    requireFields.r_soc_link_vk_dirty &&
                    !requireFields.r_soc_link_vk
                        ? 'Одно из поле соц. сетей, должно быть заполненно'
                        : ''
                }
                onBlur={(e) => {
                    setRequireFields({
                        ...requireFields,
                        r_soc_link_vk_dirty: true,
                        r_soc_link_instagram_dirty: true,
                    })
                }}
            />
            <InputFields
                label="Страница Instagram"
                value={releaseData.soc_link_instagram}
                onChange={onChangeUpdate}
                name="soc_link_instagram"
                error={
                    requireFields.r_soc_link_instagram_dirty &&
                    !requireFields.r_soc_link_instagram
                        ? true
                        : false
                }
                helperText={
                    requireFields.r_soc_link_instagram_dirty &&
                    !requireFields.r_soc_link_instagram
                        ? 'Одно из поле соц. сетей, должно быть заполненно'
                        : ''
                }
                onBlur={(e) => {
                    setRequireFields({
                        ...requireFields,
                        r_soc_link_vk_dirty: true,
                        r_soc_link_instagram_dirty: true,
                    })
                }}
            />
            <MainButton
                title="Вперед"
                color={'yt_old_dark'}
                onClick={() => goNextPage(3)}
                disabled={!activeBtn}
            />
            <MainButton
                title="Назад"
                color={'yt_old_white'}
                onClick={() => goNextPage(1)}
            />
        </>
    )
}

export { AddReleaseStep2 }
