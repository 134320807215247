import { useReleaseUpdateImageMutation } from "./releaseListAPI"

export function useUpdateReleaseImage() {


    const [releaseUpdateImage, { isLoading:isLoadingUpdateImage }] =
    useReleaseUpdateImageMutation()
        

    const handlerUpdateReleaseImage = async (URLImage, UID, afterLoad) => {

        

        let formData = new FormData()

        formData.append('input_ava', URLImage, 'filename.jpeg')
        formData.append('uid', UID)


        console.log('formData', formData)

        try {
            const newReleaseImage = await releaseUpdateImage(formData).unwrap()
            console.log(newReleaseImage)
            afterLoad()
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }




    return {
        handlerUpdateReleaseImage,
        isLoadingUpdateImage
    }
}