import { useGetUserSocialUpdateMutation } from "./profileAPI"

export function useUpdateProfileSocial() {

    const [updateSocial, { isLoading }] =
    useGetUserSocialUpdateMutation()
    

    const handlerUpdateSocial = async (obj) => {
        try {
            const newSocial = await updateSocial(obj).unwrap()
            console.log(newSocial)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }


    return {
        handlerUpdateSocial,
    }
}