import React from 'react'
import './TotalBalance.css'

const TotalBalance = ({ data }) => {
    return (
        <div className='total_balance'>
            <div className="total_sum">{`${data.print} ₽`}</div>
            <div className="description_sum">
                Минимальная сумма для вывода 150 ₽
            </div>
        </div>
    )
}

export { TotalBalance }
