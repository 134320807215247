import React from 'react'
import { DoubleText } from '../../UI/DoubleText/DoubleText'
import './UserInfoVisible.css'

const UserInfoVisible = ({ data, changeEdit }) => {
    return (
        <>
            <div className="pencil_wrapper user_info_item">
                <div className="profile_user_name">{data.display_name}</div>
                <div className="pencil" onClick={() => changeEdit(false, true, true)}>
                    <img src="assets/icon/icon_pencil.svg" alt="" />
                </div>
            </div>
            <div className="user_info_item">
                <DoubleText textLeft={'E-mail:'} textRight={data.email} />
            </div>
            <div className="user_info_item">
                <DoubleText
                    textLeft={'Номер телефона:'}
                    textRight={data.phone}
                />
            </div>
            <div className="user_info_item">
                <DoubleText
                    textLeft={'Реферальная ссылка:'}
                    textRight={`https://yourtunes.net/?r=${data.ref_code}`}
                />
            </div>
        </>
    )
}

export { UserInfoVisible }
