import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import moment from 'moment'

export const statTopReleaseListAPI = createApi({
    reducerPath: 'statTopReleaseListAPI',
    tagTypes: ['TopReleaseList'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.yourtunes.net/api/v2/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                // headers.set('authorization', `Bearer ${token}`)
                headers.set(
                    'authorization',
                    `Bearer 6321dfb66102djpjtf995hq1bqwemy99qy3hdncramq`
                )
            }
            return headers
        },
    }),
    endpoints: (build) => ({
        topReleaseList: build.query({
            query: () => `statistic/releases_top`,
        })
    }),
})

export const {
    useTopReleaseListQuery,
} = statTopReleaseListAPI
