import { useState } from "react"
import { useGetReleaseAddMutation } from "./releaseListAPI"

export function useReleaseAdd(setCurrentUID) {


    const [releaseAdd, { isLoading:isLoadingAddRelease }] =
    useGetReleaseAddMutation()
        
    const [imageAdd, setImageAdd] = useState('')

    const handlerReleaseAdd = async (urlImage, afterLoad ) => {

        

        let formData = new FormData()

        formData.append('input_ava', urlImage, 'filename.jpeg')

        console.log('formData', formData, 'urlImage', urlImage)

        try {
            const newReleaseAdd = await releaseAdd(formData).unwrap()
            console.log(newReleaseAdd)
            setImageAdd(newReleaseAdd.result.ava_link)
            afterLoad()
            setCurrentUID(newReleaseAdd.result.uid)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }


    return {
        handlerReleaseAdd,
        imageAdd,
        isLoadingAddRelease
    }

}