import React from 'react'
import { Link } from 'react-router-dom'
import './ReleaseTariff.css'

const ReleaseTariff = () => {
  return (
    <div className='release_tariff'>
        Тариф не выбран. <Link to="/profile">Перейти в профиль для выбора тарифа</Link>
    </div>
  )
}

export  {ReleaseTariff}
