import React, { useState } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetUserInfoQuery } from '../../modules/Profile/api/profileAPI'
import { useRouter } from '../hooks/useRouter'
import './ProtectedLayout.css'

const ProtectedLayout = () => {
    const [menuActive, setMenuActive] = useState(false)

    const { MenuProfile } = useRouter()

    const { data = { result: [] }, isLoading } = useGetUserInfoQuery()

    const isAuth = useSelector((state) => state.auth.isAuth)
    const token_user = useSelector((state) => state.auth.token)


    if (!isAuth) {
        return <Navigate to="/auth" />
    }

    return (
        <>
            <div
                className={
                    menuActive ? 'header_wrapper active' : 'header_wrapper'
                }
            >
                <div className={'avatar_wrapper'}>
                    <img src={data.result.ava_link} alt="AVA" />
                </div>
                <div
                    className={'hamburger_menu_wrapper'}
                    onClick={() =>
                        menuActive ? setMenuActive(false) : setMenuActive(true)
                    }
                >
                    <img src="assets/icon/icon_hamburger_menu.svg" alt="" />
                </div>
            </div>
            <div className={menuActive ? 'mobile_menu active' : 'mobile_menu'}>
                <p className="profile_name">{data.result.display_name}</p>
                <p className="profile_email">{data.result.email}</p>
                <nav>{MenuProfile()}</nav>
            </div>

            <div className="container">
                <Outlet />
            </div>
        </>
    )
}

export { ProtectedLayout }
