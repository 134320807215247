import React, { useEffect, useState } from 'react'
import { MainButtonLoad } from '../../../../UI/MainButtonLoad/MainButtonLoad'
import { useTrackAddMutation } from '../../api/releaseListAPI'
import './TrackList.css'
import { TrackListItem } from '../../../../components/TrackListItem/TrackListItem'
import { MainButton } from '../../../../UI/MainButton/MainButton'


const TrackList = ({
    goNextPage,
    typeRelease,
    trackList,
    setTrackList,
    setCurrentTrackUID,
    currentTrackUID,
    setActiveTrackListBlock,
    currentUID,
}) => {
    const printType = () => {
        if (typeRelease == 'album') {
            return {
                title: 'Тип релиза альбом',
                description:
                    'Вы должны загрузить от 4х треков, количество треков неограничено',
            }
        } else {
            return {
                title: 'Тип релиза сингл',
                description: 'Вы можете загрузить до трех треков.',
            }
        }
    }

    const [trackAdd, { isLoading: isLoadingTrackAdd }] = useTrackAddMutation()

    const handlerTrackAdd = async () => {
        try {
            const request = await trackAdd({ uid: currentUID }).unwrap()
            console.log('request add track', request)
            setTrackList(request.result)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }

    const [firstTrack, setFirstTrack] = useState(true)

    useEffect(() => {
        if (trackList.length > 1) setFirstTrack(false)
    }, [trackList])

    return (
        <>
            <div>Трэк лист!</div>
            <div>{printType().title}</div>
            <div>{printType().description}</div>
       
            <div>
                {firstTrack ? (
                    <>
                        Это Ваш первый трек нажмите на него, чтобы приступить к
                        его заполнению
                    </>
                ) : null}
                {trackList.map((track, index) => (
                    <TrackListItem
                        key={index}
                        track={track}
                        index={index}
                        setCurrentTrackUID={setCurrentTrackUID}
                        setActiveTrackListBlock={setActiveTrackListBlock}
                        currentUID={currentUID}
                        setTrackList={setTrackList}
                    />
                ))}
            </div>
            <div>
                <MainButtonLoad
                    title="Добавить ещё трек"
                    color="yt_old_green"
                    onClick={() => handlerTrackAdd()}
                    loading={isLoadingTrackAdd}
                />
                <MainButton
                    title="Назад"
                    color="yt_old_white"
                    onClick={() => {
                        setCurrentTrackUID('')
                        goNextPage(2)
                    }}
                />
            </div>
        </>
    )
}

export { TrackList }
