import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import './RadioBox.css'

const RadioBox = ({ radioValue, data }) => {
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (radioValue == data.value) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [radioValue])

    return (
        <>
            <div className="mde_radio_wrapper">
                {!checked ? (
                    <img
                        src="assets/icon/icon_checkbox_circle_note_checked.svg"
                        className="mde_radio_img_circle"
                        alt="note_checked"
                    />
                ) : (
                    <img
                        src="assets/icon/icon_checkbox_circle_checked.svg"
                        className="mde_radio_img_circle"
                        alt="checked"
                    />
                )}
                <label htmlFor={data.value} className="mde_radio_label">
                    {data.title}
                    {data.price ? <span className='modal_tariff_price'>{`( ${data.price} руб.)`}</span> : null}
                </label>
                <input
                    type="radio"
                    id={data.value}
                    name={data.name}
                    value={data.value}
                    className="mde_radio_input"
                />
            </div>
        </>
    )
}

export { RadioBox }
