import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import moment from 'moment'

export const releaseListAPI = createApi({
    reducerPath: 'releaseListAPI',
    tagTypes: ['Release', 'Track'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.yourtunes.net/api/v2/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
                // headers.set(
                //     'authorization',
                //     `Bearer 6321dfb66102djpjtf995hq1bqwemy99qy3hdncramq`
                // )
            }
            return headers
        },
    }),
    endpoints: (build) => ({
        getReleaseList: build.query({
            query: () => `release/list`,
            transformResponse(data, meta, arg) {
                for (let i = 0; i < data.result.length; i++) {
                    if (data.result[i].status == 6) {
                        data.result[i].status_name = 'Черновик'
                        data.result[i].status_style = 'status_draft'
                    } else if (data.result[i].status == 4) {
                        data.result[i].status_name = 'На модерации'
                        data.result[i].status_style = 'status_moderation'
                    } else if (data.result[i].status == 3) {
                        data.result[i].status_name = 'Опубликовано'
                        data.result[i].status_style = 'status_published'
                    } else if (data.result[i].status == 2) {
                        data.result[i].status_name = 'Есть ошибки'
                        data.result[i].status_style = 'status_draft'
                    } else {
                        data.result[i].status_name = data.result[i].status
                        data.result[i].status_style = 'status_draft'
                    }

                    if (data.result[i].type == 'album') {
                        data.result[i].type_name = 'Альбом'
                    } else if (data.result[i].type == 'single') {
                        data.result[i].type_name = 'Сингл'
                    } else if (data.result[i].type == 'ringtone') {
                        data.result[i].type_name = 'Рингтон'
                    } else if (data.result[i].type == 'clip') {
                        data.result[i].type_name = 'Клип'
                    } else {
                        data.result[i].type_name = data.result[i].type
                    }

                    data.result[i].main_singers_print =
                        data.result[i].main_singers.join(', ')

                    if (data.result[i].public_date == null)
                        data.result[i].public_date_print = 'Дата отсутствует'
                    else
                        data.result[i].public_date_print = moment(
                            data.result[i].public_date,
                            'YYYY-MM-DD'
                        ).format('DD.MM.YYYY')

                    if (data.result[i].status == 3)
                        data.result[i].published = true
                    else data.result[i].published = false
                }
                return data
            },
            providesTags: (data, error, arg) =>
                data.result
                    ? [
                          ...data.result.map(({ uid }) => ({
                              type: 'Release',
                              uid,
                          })),
                          { type: 'Release', uid: 'LIST' },
                      ]
                    : [{ type: 'Release', uid: 'LIST' }],
        }),
        getReleaseAdd: build.mutation({
            query: (body) => ({
                url: `release/add`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Release'],
        }),
        getReleaseImage: build.mutation({
            query: (body) => ({
                url: `release/get_ava`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Release'],
        }),
        releaseDelete: build.mutation({
            query: (body) => ({
                url: `release/delete`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Release'],
        }),
        releaseUpdateImage: build.mutation({
            query: (body) => ({
                url: `release/update_ava`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Release'],
        }),
        releaseGetInfo: build.mutation({
            query: (body) => ({
                url: `release/get`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Release'],
        }),
        releaseUpdateInfo: build.mutation({
            query: (body) => ({
                url: `release/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Release'],
        }),
        getTrackList: build.mutation({
            query: (body) => ({
                url: `release/track_list`,
                method: 'POST',
                body,
            }),
            providesTags: (data, error, arg) =>
            data.result
                ? [
                      ...data.result.map(({ uid }) => ({
                          type: 'Track',
                          uid,
                      })),
                      { type: 'Track', uid: 'LIST' },
                  ]
                : [{ type: 'Track', uid: 'LIST' }],
        }),
        getTrackInfo: build.mutation({
            query: (body) => ({
                url: `release/get_track`,
                method: 'POST',
                body,
            }),
            // invalidatesTags: ['Track'],
        }),
        trackUpdateInfo: build.mutation({
            query: (body) => ({
                url: `release/update_track`,
                method: 'POST',
                body,
            }),
            // invalidatesTags: ['Track'],
        }),
        uploadTrack: build.mutation({
            query: (body) => ({
                url: `release/track_upload`,
                method: 'POST',
                body,
            }),
            // invalidatesTags: ['Track'],
        }),
        releaseToPublished: build.mutation({
            query: (body) => ({
                url: `release/to_publication`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Release'],
        }),
        trackAdd: build.mutation({
            query: (body) => ({
                url: `release/track_add`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Track', uid: 'LIST' }],
        }),
        trackDelete: build.mutation({
            query: (body) => ({
                url: `release/track_delete`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Track'],
        }),
    }),
})

export const {
    useGetReleaseListQuery,
    useGetReleaseAddMutation,
    useGetReleaseImageMutation,
    useReleaseDeleteMutation,
    useReleaseUpdateImageMutation,
    useReleaseGetInfoMutation,
    useReleaseUpdateInfoMutation,
    useGetTrackListMutation,
    useGetTrackInfoMutation,
    useTrackUpdateInfoMutation,
    useUploadTrackMutation,
    useReleaseToPublishedMutation,
    useTrackAddMutation,
    useTrackDeleteMutation
} = releaseListAPI
