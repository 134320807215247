import React from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import './ChangeImage.css'
import { useUpdateProfileAva } from '../../modules/Profile/api/useUpdateProfileAva'
import { MainButton } from '../../UI/MainButton/MainButton'

const ChangeImage = ({ src, title, crop, setCrop, loadImg}) => {

    const { handlerUpdateAva } = useUpdateProfileAva()


    const sendAva = () => {
        console.log('start', crop)
        let wpx = loadImg.width / 100,
            hpx = loadImg.height / 100,
            canvas = document.createElement('canvas'),
            coiff = 1,
            w = wpx * crop.width,
            h = hpx * crop.height

        

        if (w > 500) {
            coiff = w / 500
            
            canvas.width = 500
            canvas.height = 500
        } else {
            canvas.width = w
            canvas.height = h
        }

        console.log(loadImg.img)

        canvas.getContext('2d')
            .drawImage(loadImg.img, -1 * (wpx * crop.x) / coiff,
                -1 * (hpx * crop.y) / coiff, loadImg.width / coiff,
                loadImg.height / coiff)
        
        let dataUrl = canvas.toDataURL('images/jpeg', 0.6)
        canvas.toBlob(function(blob) {
            handlerUpdateAva(blob)
        })
        
    }

    return (
        <div className="change_image_loader">
            <div>{title}</div>
            <ReactCrop
                minHeight={100}
                minWidth={100}
                maxHeight={1000}
                maxWidth={1000}
                // style={{ width: screenWidth, height: screenWidth }}
                crop={crop}
                circularCrop={true}
                aspect={1 / 1}
                onChange={(crop, percentCrop) => {
                    // console.log(crop)

                    setCrop(percentCrop)
                }}
                onComplete={(crop, percentCrop) => {
                    console.log('crop', percentCrop)
                }}
            >
                <img src={src} />
            </ReactCrop>
            <div>
               <span>{crop.width}px</span><span>{crop.height}px</span> 
            </div>
            <div>
                <MainButton 
                    title="Отправить"
                    onClick={() => sendAva()}
                    className="bg_dark"
                />
            </div>
        </div>
    )
}

export { ChangeImage }
