import React from 'react'

import { TotalBalance } from '../modules/Balance/screens/TotalBalance/TotalBalance'
import { BalanceList } from '../modules/Balance/screens/BalanceList/BalanceList'

import { useGetUserBalanceQuery } from '../modules/Balance/api/balanceAPI'
import { useGetUserBalanceListQuery } from '../modules/Balance/api/balanceAPI'

const BalancePage = () => {

    const { data = { result: [] }, isLoading } = useGetUserBalanceQuery()

    const { data:dataBalanceList = { result: [] } } = useGetUserBalanceListQuery()


    return <>
        <TotalBalance data={data.result} />
        <BalanceList  data={dataBalanceList.result} />
    
    </>
}

export { BalancePage }
