import React from 'react'
import { StatWindow } from '../../../../components/StatWindow/StatWindow'
import { DoubleText } from '../../../../UI/DoubleText/DoubleText'
import { useTopReleaseListQuery } from '../../api/statTopReleaseListAPI'
import './TopReleaseList.css'


const TopReleaseList = () => {
    const { data = { result: [] }, isLoading } = useTopReleaseListQuery()

    console.log('top release DATA', data)

    return (
        <StatWindow>
            <div className="top_release_list_container">
                {data.result.map((el, index) => {
                    return (
                        <div key={el.upc} className="top_release_list_item">
                            <div>{index + 1}.</div>
                            <div>
                                <img src={el.ava_short_link} alt="img" />
                            </div>
                            <div>
                                <div>{el.name}</div>
                                <div>{el.main_singers}</div>
                                <DoubleText
                                    textLeft="Кол-во стримов: "
                                    textRight={el.streamings}
                                />
                                <DoubleText
                                    textLeft="UPC: "
                                    textRight={el.upc}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </StatWindow>
    )
}

export { TopReleaseList }
