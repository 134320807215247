import React, { useState } from 'react'
import { InputFields } from '../../UI/InputFields/InputFields'
import { DoubleButton } from '../DoubleButton/DoubleButton'

const UserInfoChange = ({ data, changeEdit, handlerUpdate }) => {


    const [userInfo, setUserInfo] = useState({
        display_name: data.display_name,
        email: data.email,
        phone: data.phone,
    })


    return (
        <div className="profile_change_block">
            <InputFields
                textLabel={'ФИО'}
                id={'profile_user_name'}
				defaultValue={data.display_name}
                onChange={(event) =>
                    setUserInfo({ ...userInfo, display_name: event.target.value })
                }
            />
            <InputFields
                textLabel={'E-mail'}
                id={'profile_user_email'}
                defaultValue={data.email}
                onChange={(event) =>
                    setUserInfo({ ...userInfo, email: event.target.value })
                }
            />
            <InputFields
                textLabel={'Номер телефона'}
                id={'profile_user_number'}
                defaultValue={data.phone}
                onChange={(event) =>
                    setUserInfo({ ...userInfo, phone: event.target.value })
                }
            />
            <DoubleButton
                leftTitle={'Отмена'}
                leftClassName={'bg_white'}
                rightTitle={'Сохранить'}
                rightClassName={'bg_dark'}
                leftOnClick={() => changeEdit(true, true, true)}
				// rightOnClick={() => console.log(userInfo)}
                rightOnClick={() => handlerUpdate(userInfo)}
            />
        </div>
    )
}

export { UserInfoChange }
