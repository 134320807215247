import React from 'react'
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material'

const YtSelect = ({ idLabel, data, label, ...props }) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="release_type_label">{label}</InputLabel>
            <Select
                {...props}
                labelId="release_type_label"
                label={label}
                id="release_type"
            >
                {data?.map((el, index) => {
                    return (
                        <MenuItem key={index} value={el.value}>
                            {el.label}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export { YtSelect }
