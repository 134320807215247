import React, { useState } from 'react'
import { UserInfoChange } from '../../../../components/UserInfoChange/UserInfoChange'
import { UserInfoVisible } from '../../../../components/UserInfoVisible/UserInfoVisible'
import { UserPasswordChange } from '../../../../components/UserPasswordChange/UserPasswordChange'
import { UserPasswordVisible } from '../../../../components/UserPasswordVisible/UserPasswordVisible'
import { UserSocialChange } from '../../../../components/UserSocialChange/UserSocialChange'
import { UserSocialVisible } from '../../../../components/UserSocialVisible/UserSocialVisible'
import { useGetUserInfoQuery } from '../../api/profileAPI'
import { useUpdateProfileInfo } from '../../api/useUpdateProfileInfo'

import './UserInfo.css' //Общие стили для компонентов вынесены сюда, чтобы не дублировать в каждом

const UserInfo = () => {
    const { data = { result: [] } } = useGetUserInfoQuery()


    const { handlerUpdateInfo } = useUpdateProfileInfo()


    const [editScreen, setEditScreen] = useState({
        userInfoVisible: true,
        userPasswordVisible: true,
        userSocialVisible: true,
    })



    const changeScreenEdit = (info, pass, social) => {
        setEditScreen({
            userInfoVisible: info,
            userPasswordVisible: pass,
            userSocialVisible: social,
        })
    }

    return (
        <div className="profile_user_container">
            {editScreen.userInfoVisible ? (
                <UserInfoVisible
                    data={data?.result}
                    changeEdit={changeScreenEdit}
                />
            ) : (
                <UserInfoChange
                    data={data?.result}
                    changeEdit={changeScreenEdit}
                    handlerUpdate={handlerUpdateInfo}
                />
            )}

            {editScreen.userPasswordVisible ? (
                <UserPasswordVisible changeEdit={changeScreenEdit} />
            ) : (
                <UserPasswordChange changeEdit={changeScreenEdit} />
            )}

            {editScreen.userSocialVisible ? (
                <UserSocialVisible
                    data={data?.result}
                    changeEdit={changeScreenEdit}
                />
            ) : (
                <UserSocialChange
                    data={data?.result}
                    changeEdit={changeScreenEdit}
                />
            )}
        </div>
    )
}

export { UserInfo }
