import React from 'react'
import { Platforms } from '../modules/Statistics/screens/Platforms/Platforms'
import { TopReleaseList } from '../modules/Statistics/screens/TopReleaseList/TopReleaseList'

const StatisticsPage = () => {
  return (
    <>

        <Platforms />
        <TopReleaseList />
    </>
  )
}

export  {StatisticsPage}
