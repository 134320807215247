import React from 'react'
import { Button } from '@mui/material'
import { MainButtonLoad } from '../../UI/MainButtonLoad/MainButtonLoad'

const UploadFile = ({ UID, file, setFile, uploadTrack, isLoadingUpload }) => {
    var fReader = new FileReader()

    const handlerUpload = async (uploadFile) => {
        let formData = new FormData()

        formData.append('input_file', uploadFile, 'filename.mp3')
        formData.append('uid', UID)

        console.log('formData', formData)

        try {
            const newFile = await uploadTrack(formData).unwrap()
            console.log('newFile', newFile)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }

    const onChangeFile = (event) => {
        fReader.readAsDataURL(event.target.files[0])

        console.log('event.target.files[0]', event.target.files[0])

        setFile(event.target.files[0])

        handlerUpload(event.target.files[0])
    }

    return (
        <div>
            <div>Загрузите файл</div>
            <MainButtonLoad
                variant="contained"
                component="label"
                onChange={onChangeFile}
                fullWidth
                loading={isLoadingUpload}
                color="yt_old_dark"
                title={file.name != '' ? file.name : 'Выберите файл'}
            >
                <input type="file" hidden />
            </MainButtonLoad>
        </div>
    )
}

export { UploadFile }
