import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './TrackItem.css'
import { DoubleText } from '../../UI/DoubleText/DoubleText'
import { useGetTrackInfoMutation } from '../../modules/Release/api/releaseListAPI'
import CircularProgress from '@mui/material/CircularProgress'
import { DoubleMassiveText } from '../../UI/DoubleMassiveText/DoubleMassiveText'

const TrackItem = ({ data, index, expanded, setExpanded }) => {


    // useEffect(() => {


    // }, [])


    const [trackDATA, setTrackDATA] = useState({
        track_year: '',
        remix_author: '',
        isrc: '',
        insta_index: '',
        main_song: false,
        filthy_lang: false,
        is_instrumental: false,
        song_text: '',
        genre: null,
        title_lang: '',
        lang: '',
        singers: [''],
        singers_other: [''],
        arranger: [['', '']],
        authors: [['', '']],
        composer: [['', '']],
    })

    const [getInfo, { isLoading }] = useGetTrackInfoMutation()

    const handlerGetInfo = async () => {
        try {
            const request = await getInfo({ uid: data.uid }).unwrap()

            console.log('request', request)

            setTrackDATA(request.result)
        } catch (e) {}
    }
    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
        handlerGetInfo()
    }


    const printBoolean = (name) => {
        if(trackDATA[name] == true) return 'Да'
        else return 'Нет'
    }

    return (
        <Accordion
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
            style={{ borderRadius: 16, marginTop: 10, marginBottom: 10 }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{data.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <Typography component={'span'}>
                        <DoubleMassiveText
                            textLeft={'Исполнитель:'}
                            data={trackDATA.singers}
                        />
                        <DoubleMassiveText
                            textLeft={'При участии:'}
                            data={trackDATA.singers_other}
                        />
                        <DoubleText
                            textLeft={'Год записи:'}
                            textRight={trackDATA.track_year}
                        />
                        <DoubleText
                            textLeft={'Язык заголовка песни:'}
                            textRight={trackDATA.title_lang}
                        />
                        <DoubleText
                            textLeft={'Язык песни:'}
                            textRight={trackDATA.lang}
                        />
                        <DoubleText
                            textLeft={'Имя автора ремикса:'}
                            textRight={trackDATA.remix_author}
                        />
                        <DoubleText
                            textLeft={'ISRC-код:'}
                            textRight={trackDATA.isrc}
                        />
                        <DoubleText
                            textLeft={'Начало трека для TikTok / Instagram:'}
                            textRight={trackDATA.insta_index}
                        />
                        <DoubleText
                            textLeft={'Основная песня альбома:'}
                            textRight={printBoolean('main_song')}
                        />
                        <DoubleText
                            textLeft={'Нецензурная лексика:'}
                            textRight={printBoolean('filthy_lang')}
                        />
                        <DoubleText
                            textLeft={'Инструментальный трек:'}
                            textRight={printBoolean('is_instrumental')}
                        />
                    </Typography>
                )}
            </AccordionDetails>
        </Accordion>
    )
}
// track_year: request.result.track_year,
//                 remix_author: request.result.remix_author,
//                 isrc: request.result.isrc,
//                 insta_index: request.result.insta_index,
//                 main_song: request.result.main_song,
//                 filthy_lang: request.result.filthy_lang,
//                 is_instrumental: request.result.is_instrumental,
//                 song_text: request.result.song_text,
//                 genre: request.result.genre,
//                 title_lang: request.result.title_lang,
//                 lang: request.result.lang,
export { TrackItem }
