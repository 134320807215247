import { TextField } from '@mui/material'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded'
import React from 'react'

const DoubleMultipleTextInput = ({
    UID,
    name,
    textLabel,
    mainArg,
    requireFields,
    setRequireFields,
    setArg,
    debounced,
    ...props
}) => {
    const changeArg = (e, index, u) => {
        let newMassive = []

        for (let i = 0; i < mainArg.length; i++) {
            newMassive.push([...mainArg[i]])
        }

        newMassive[index][u] = e.target.value

        // console.log('newMassive', newMassive)

        let newArgSend = { uid: UID }
        newArgSend[name] = newMassive

        debounced(newArgSend)
        setArg(newMassive)
    }

    const addArg = () => {
        setArg([...mainArg, ['', '']])
    }

    const deleteArg = (index) => {
        let newMassive = [...mainArg]
        newMassive.splice(index, 1)

        let newArgSend = { uid: UID }
        newArgSend[name] = newMassive

        debounced(newArgSend)
        setArg(newMassive)
    }

    const onBlurDirtyArg = () => {
        let newArg = { ...requireFields }
        newArg[`r_${name}_dirty`] = true
        setRequireFields(newArg)
    }

    return (
        <div
            style={{
                padding: 15,
                borderRadius: 10,
                backgroundColor: '#F1F1F1',
                marginBottom: 10,
                marginTop: 10,
            }}
        >
            {mainArg.map((element, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 15,
                        }}
                    >
                        {requireFields ? (
                            <>
                                <div>
                                    <TextField
                                        label={index == 0 ? textLabel : ''}
                                        placeholder="Фамилия"
                                        value={element ? element[0] : ''}
                                        onChange={(e) => changeArg(e, index, 0)}
                                        margin="dense"
                                        onBlur={onBlurDirtyArg}
                                        error={
                                            index == 0 &&
                                            requireFields[`r_${name}_dirty`] &&
                                            !requireFields[`r_${name}`]
                                                ? true
                                                : false
                                        }
                                        fullWidth
                                    />
                                    <TextField
                                        placeholder="Имя"
                                        value={element ? element[1] : ''}
                                        onChange={(e) => changeArg(e, index, 1)}
                                        margin="dense"
                                        onBlur={onBlurDirtyArg}
                                        error={
                                            index == 0 &&
                                            requireFields[`r_${name}_dirty`] &&
                                            !requireFields[`r_${name}`]
                                                ? true
                                                : false
                                        }
                                        helperText={
                                            index == 0 &&
                                            requireFields[`r_${name}_dirty`] &&
                                            !requireFields[`r_${name}`]
                                                ? 'Фамилия и имя должны быть заполнены'
                                                : ''
                                        }
                                        fullWidth
                                    />
                                </div>
                                {index == 0 ? (
                                    <AddCircleOutlineRoundedIcon
                                        style={{
                                            marginLeft: 10,
                                            color: '#4AB823',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => addArg()}
                                    />
                                ) : (
                                    <RemoveCircleOutlineRoundedIcon
                                        style={{
                                            marginLeft: 10,
                                            color: '#E60C0C',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => deleteArg(index)}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <div>
                                    <TextField
                                        label={index == 0 ? textLabel : ''}
                                        placeholder="Фамилия"
                                        value={element ? element[0] : ''}
                                        onChange={(e) => changeArg(e, index, 0)}
                                        margin="dense"
                                        fullWidth
                                    />
                                    <TextField
                                        placeholder="Имя"
                                        value={element ? element[1] : ''}
                                        onChange={(e) => changeArg(e, index, 1)}
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                {index == 0 ? (
                                    <AddCircleOutlineRoundedIcon
                                        style={{
                                            marginLeft: 10,
                                            color: '#4AB823',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => addArg()}
                                    />
                                ) : (
                                    <RemoveCircleOutlineRoundedIcon
                                        style={{
                                            marginLeft: 10,
                                            color: '#E60C0C',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => deleteArg(index)}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    // <div
                    //     key={index}
                    //     style={{ display: 'flex', alignItems: 'center' }}
                    // >
                    //     {requireFields ? (
                    //         <TextField
                    //             {...props}
                    //             onChange={(e) => changeArg(e, index)}
                    //             label={index == 0 ? textLabel : ''}
                    //             variant={index == 0 ? 'outlined' : 'filled'}
                    //             value={el}
                    //             error={
                    //                 index == 0 &&
                    //                 requireFields[`r_${name}_dirty`] &&
                    //                 !requireFields[`r_${name}`]
                    //                     ? true
                    //                     : false
                    //             }
                    //             helperText={
                    //                 index == 0 &&
                    //                 requireFields[`r_${name}_dirty`] &&
                    //                 !requireFields[`r_${name}`]
                    //                     ? 'Поле должно быть заполненным'
                    //                     : ''
                    //             }
                    //             onBlur={onBlurDirtyArg}
                    //             fullWidth
                    //         />
                    //     ) : (
                    //         <TextField
                    //             {...props}
                    //             onChange={(e) => changeArg(e, index)}
                    //             label={index == 0 ? textLabel : ''}
                    //             variant={index == 0 ? 'outlined' : 'filled'}
                    //             value={el}
                    //             fullWidth
                    //         />
                    //     )}

                    // {index == 0 ? (
                    //     <AddCircleOutlineRoundedIcon
                    //         style={{
                    //             marginLeft: 10,
                    //             color: '#4AB823',
                    //             cursor: 'pointer',
                    //         }}
                    //         onClick={() => addArg()}
                    //     />
                    // ) : (
                    //     <RemoveCircleOutlineRoundedIcon
                    //         style={{
                    //             marginLeft: 10,
                    //             color: '#E60C0C',
                    //             cursor: 'pointer',
                    //         }}
                    //         onClick={() => deleteArg(index)}
                    //     />
                    // )}
                    // </div>
                )
            })}
        </div>
    )
}

export { DoubleMultipleTextInput }
