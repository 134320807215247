import React from 'react'
import './BalanceItem.css'

const BalanceItem = ({ data, index }) => {
    return (
        <div className="balance_item">
            <div className='index'>{index + 1}</div>
            <div className="main_info">
                <div className={data.class_name}>{data.print_type}</div>
                <div>{`${data.sum_correct} ₽`}</div>
                <div>{data.print_time}</div>
            </div>
            <div className="download_img">
                <div>
                    <img src="assets/icon/icon_download.svg" alt="dwn" />
                </div>
            </div>
            <div className="download_img">
                <div>
                    <img src="assets/icon/icon_pdf.svg" alt="PDF" />
                </div>
            </div>
        </div>
    )
}

export { BalanceItem }
