import React from 'react'
import { useEffect } from 'react'
import './Modal.css'
import { useModal } from '../../components/hooks/useModal'

const Modal = ({ children, active, close }) => {

    const { modalIsClose } = useModal()

    return (
        <div
            className={active ? 'modal_bg active' : 'modal_bg'}
            onClick={() => modalIsClose(close)}
        >
            <div
                className={active ? 'modal_container active' : 'modal_content'}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="modal_exit" onClick={() => modalIsClose(close)}>
                    <img src="assets/icon/icon_cross_black.svg" alt="X" />
                </div>
                {children}
            </div>
        </div>
    )
}

export { Modal }
