import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './authSlice'

import { profileAPI } from '../modules/Profile/api/profileAPI'
import { userAPI } from '../modules/Auth/api/userAPI'
import { balanceAPI } from '../modules/Balance/api/balanceAPI'
import { releaseListAPI } from '../modules/Release/api/releaseListAPI'
import { statReleaseStoreAPI } from '../modules/Statistics/api/statReleaseStoreAPI'
import { statTopReleaseListAPI } from '../modules/Statistics/api/statTopReleaseListAPI'


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
    blacklist: ['userAPI'],
}

const rootReducer = combineReducers({
    [userAPI.reducerPath]: userAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,
    [balanceAPI.reducerPath]: balanceAPI.reducer,
    [releaseListAPI.reducerPath]: releaseListAPI.reducer,
    [statReleaseStoreAPI.reducerPath]: statReleaseStoreAPI.reducer,
    [statTopReleaseListAPI.reducerPath]: statTopReleaseListAPI.reducer,
    auth: authReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        })
            .concat(userAPI.middleware)
            .concat(profileAPI.middleware)
            .concat(balanceAPI.middleware)
            .concat(releaseListAPI.middleware)
            .concat(statReleaseStoreAPI.middleware)
            .concat(statTopReleaseListAPI.middleware)
})

export const persistor = persistStore(store)
