import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// import moment from 'moment'

export const userAPI = createApi({
    reducerPath: 'userAPI',
    tagTypes: ['Release'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.yourtunes.net/api/v2/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
                // headers.set(
                //     'authorization',
                //     `Bearer 6321dfb66102djpjtf995hq1bqwemy99qy3hdncramq`
                // )
            }
            return headers
        },
    }),
    endpoints: (build) => ({
        getUserToken: build.mutation({
            query: (credentials) => ({
                url: `/auth`,
                method: 'POST',
                body: credentials,
            }),
        }),
        // getUserInfo: build.query({
        //     query: () => `user/get_info`,
        //     transformResponse(data, meta, arg) {
        //         if (data.result.tarif_end == null)
        //             data.result.tarif_end_print = 'Дата отсутствует'
        //         else
        //             data.result.tarif_end_print = moment(
        //                 data.result.tarif_end,
        //                 'YYYY-MM-DD'
        //             ).format('DD.MM.YYYY')

        //         return data
        //     },
        //     providesTags: ['User'],
        // }),
        // updateUserName: build.mutation({
        //     query: (body) => ({
        //         url: `/user/update`,
        //         method: 'POST',
        //         body,
        //     }),
        //     invalidatesTags: ['User'],
        // }),
        // updateUserPassword: build.mutation({
        //     query: (body) => ({
        //         url: `/user/update_password`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // updateUserSocial: build.mutation({
        //     query: (body) => ({
        //         url: `/user/update_soc`,
        //         method: 'POST',
        //         body,
        //     }),
        //     invalidatesTags: ['User'],
        // }),
        // getReleaseList: build.query({
        //     query: () => `release/list`,
        //     transformResponse(data, meta, arg) {
        //         for (let i = 0; i < data.result.length; i++) {
        //             if (data.result[i].status == 6) {
        //                 data.result[i].status_name = 'Черновик'
        //                 data.result[i].status_style = 'status_draft'
        //             } else if (data.result[i].status == 4) {
        //                 data.result[i].status_name = 'На модерации'
        //                 data.result[i].status_style = 'status_moderation'
        //             } else if (data.result[i].status == 3) {
        //                 data.result[i].status_name = 'Опубликовано'
        //                 data.result[i].status_style = 'status_published'
        //             } else if (data.result[i].status == 2) {
        //                 data.result[i].status_name = 'Есть ошибки'
        //                 data.result[i].status_style = 'status_draft'
        //             } else {
        //                 data.result[i].status_name = data.result[i].status
        //                 data.result[i].status_style = 'status_draft'
        //             }

        //             if (data.result[i].type == 'album') {
        //                 data.result[i].type_name = 'Альбом'
        //             } else if (data.result[i].type == 'single') {
        //                 data.result[i].type_name = 'Сингл'
        //             } else if (data.result[i].type == 'ringtone') {
        //                 data.result[i].type_name = 'Рингтон'
        //             } else if (data.result[i].type == 'clip') {
        //                 data.result[i].type_name = 'Клип'
        //             } else {
        //                 data.result[i].type_name = data.result[i].type
        //             }

        //             data.result[i].main_singers_print =
        //                 data.result[i].main_singers.join(', ')

        //             if (data.result[i].public_date == null)
        //                 data.result[i].public_date_print = 'Дата отсутствует'
        //             else
        //                 data.result[i].public_date_print = moment(
        //                     data.result[i].public_date,
        //                     'YYYY-MM-DD'
        //                 ).format('DD.MM.YYYY')

        //             if (data.result[i].status == 3)
        //                 data.result[i].published = true
        //             else data.result[i].published = false
        //         }
        //         return data
        //     },
        //     providesTags: (data, error, arg) =>
        //         data.result
        //             ? [
        //                   ...data.result.map(({ uid }) => ({
        //                       type: 'Release',
        //                       uid,
        //                   })),
        //                   { type: 'Release', uid: 'LIST' },
        //               ]
        //             : [{ type: 'Release', uid: 'LIST' }],
        // }),
        // addReleaseAva: build.mutation({
        //     query: (img) => ({
        //         url: `/release/add`,
        //         method: 'POST',
        //         body: img,
        //         headers: { input_ava: 'Content-Type: multipart/form-data' },
        //     }),
        //     invalidatesTags: [{ type: 'Release', uid: 'LIST' }],
        // }),
        // getReleaseAva: build.mutation({
        //     query: (uid) => ({
        //         url: `/release/get_ava`,
        //         method: 'POST',
        //         body: uid,
        //     }),
        // }),
        // updateReleaseAva: build.mutation({
        //     query: (uid) => ({
        //         url: `/release/update_ava`,
        //         method: 'POST',
        //         body: uid,
        //     }),
        // }),
        // deleteRelease: build.mutation({
        //     query: (uid) => ({
        //         url: `/release/delete`,
        //         method: 'POST',
        //         body: uid,
        //     }),
        //     invalidatesTags: [{ type: 'Release', uid: 'LIST' }],
        // }),
        // getInfoRelease: build.mutation({
        //     query: (uid) => ({
        //         url: `/release/get`,
        //         method: 'POST',
        //         body: uid,
        //     }),
        //     invalidatesTags: [{ type: 'Release', uid: 'LIST' }],
        // }),
        // updateInfoRelease: build.mutation({
        //     query: (body) => ({
        //         url: `/release/update`,
        //         method: 'POST',
        //         body,
        //     }),
        //     invalidatesTags: [{ type: 'Release', uid: 'LIST' }],
        // }),
        // confirmUserToken: build.query({
        //     query: () => `user/is_token`,
        // }),
        // updateStatisticsRelease: build.mutation({
        //     query: (body) => ({
        //         url: `/statistic/releases`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // updateStatisticsStores: build.mutation({
        //     query: (body) => ({
        //         url: `/statistic/release_stores`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // getReleaseTopList: build.mutation({
        //     query: (body) => ({
        //         url: `/statistic/releases_top`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // getListTrack: build.mutation({
        //     query: (body) => ({
        //         url: `/release/track_list`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // addListTrack: build.mutation({
        //     query: (body) => ({
        //         url: `/release/track_add`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // getTrack: build.mutation({
        //     query: (body) => ({
        //         url: `/release/get_track`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // deleteTrack: build.mutation({
        //     query: (body) => ({
        //         url: `/release/track_delete`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // updateTrack: build.mutation({
        //     query: (body) => ({
        //         url: `/release/update_track`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // trackUpload: build.mutation({
        //     query: (body) => ({
        //         url: `/release/track_upload`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // publicationRelease: build.mutation({
        //     query: (body) => ({
        //         url: `/release/to_publication`,
        //         method: 'POST',
        //         body,
        //     }),
        // }),
        // getUserBalance: build.query({
        //     query: () => `/balance/get`,
        // }),
        // getUserBalanceList: build.query({
        //     query: () => `/balance/list`,
        // }),
    }),
})

export const {
    useGetUserTokenMutation,
} = userAPI
