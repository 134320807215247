import { useGetUserInfoUpdateMutation } from "./profileAPI"

export function useUpdateProfileInfo() {


    const [updateInfo, { isLoading: isLoadingUpdateInfo }] =
        useGetUserInfoUpdateMutation()
    

    const handlerUpdateInfo = async (obj) => {
        try {
            const newInfo = await updateInfo(obj).unwrap()
            console.log(newInfo)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }


    return {
        handlerUpdateInfo,
    }

}