import React from 'react'
import { Slider, Typography } from '@mui/material'

const YtSlider = ({ textLabel, ...props }) => {
    return (
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Typography>{textLabel}</Typography>
            <Slider
                {...props}
                valueLabelDisplay="auto"
            />
        </div>
    )
}

export { YtSlider }
