import { useState } from "react"
import { useGetReleaseImageMutation } from "./releaseListAPI"

export function useGetReleaseImage() {

    const [image, setImage] = useState('')
   
    const [getReleaseImage, { isLoading }] =
    useGetReleaseImageMutation()
        

    const handlerGetReleaseImage = async (UID) => {

    
        try {
            const releaseImage = await getReleaseImage({uid: UID}).unwrap()
            console.log(releaseImage)
            setImage(releaseImage.result.ava_link)
            
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }


    return {
        handlerGetReleaseImage,
        image
        
    }

}