import { createSlice } from '@reduxjs/toolkit';



const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        isAuth: false,
        userAva: null,
        userTarif: null
    },
    reducers: {
        getToken: (state, {payload: tokenUser }) => {

           
            if(tokenUser.status === 'ok')
                state.token = tokenUser.result
          
            if(state.token != undefined || state.token != null)
                state.isAuth = true
         

        },
        deleteToken: (state, { payload }) => {
            state.token = null
            state.isAuth = false
        },
    },
})


export const { getToken, deleteToken } = authSlice.actions

export default authSlice.reducer