import { Routes, Route, NavLink } from 'react-router-dom'
import { HomeLayout } from '../HomeLayout/HomeLayout'
import { ProtectedLayout } from '../ProtectedLayout/ProtectedLayout'

import { deleteToken } from '../../store/authSlice'
import { useDispatch } from 'react-redux'

import { AuthPage } from '../../pages/AuthPage'
import { ProfilePage } from '../../pages/ProfilePage'
import { BalancePage } from '../../pages/BalancePage'
import { RegPage } from '../../pages/RegPage'
import { ReleaseListPage } from '../../pages/ReleaseListPage'
import { StatisticsPage } from '../../pages/StatisticsPage'

export function useRouter() {
    const dispatch = useDispatch()

    const Routers = () => {
        return (
            <Routes>
                <Route element={<HomeLayout />}>
                    <Route path="/auth" element={<AuthPage />} />
                    <Route path="/reg" element={<RegPage />} />
                </Route>
                <Route path="/" element={<ProtectedLayout />}>
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/relizy" element={<ReleaseListPage />} />
                    <Route path="/balance" element={<BalancePage />} />
                    <Route path="/statistika" element={<StatisticsPage />} />
                </Route>
            </Routes>
        )
    }

    const MenuNoAuth = () => {
        return (
            <ul>
                <li>
                    <NavLink to="/auth">Войти</NavLink>
                </li>
                <li>
                    <NavLink to="/reg">Регистрация</NavLink>
                </li>
            </ul>
        )
    }

    const MenuProfile = () => {
        return (
            <ul>
                <li>
                    <NavLink to="/profile">Профиль</NavLink>
                </li>
                <li>
                    <NavLink to="/relizy">Мои релизы</NavLink>
                </li>
                <li>
                    <NavLink to="/balance">Баланс</NavLink>
                </li>
                <li>
                    <NavLink to="/statistika">Статистика</NavLink>
                </li>
                <li onClick={() => dispatch(deleteToken())}>Выход</li>
            </ul>
        )
    }

    return {
        Routers,
        MenuNoAuth,
        MenuProfile,
    }
}
