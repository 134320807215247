import React from 'react'
import { Registration } from '../modules/Registration/screens/Registration/Registration'

const RegPage = () => {
    return <>
        <Registration />
    </>
}

export { RegPage }
