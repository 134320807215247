import React from 'react'
import { useGetUserInfoQuery } from '../modules/Profile/api/profileAPI'
import { Loading } from '../UI/Loading/Loading'
import { AvaProfile } from '../modules/Profile/screens/AvaProfile/AvaProfile'
import { UserInfo } from '../modules/Profile/screens/UserInfo/UserInfo'
import { TariffProfile } from '../modules/Profile/screens/TariffProfile/TariffProfile'


const ProfilePage = () => {

    const { data = { result: [] }, isLoading } = useGetUserInfoQuery()


    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>  
                    <AvaProfile avaLink={data?.result.ava_link} />
                    <UserInfo/>
                    <TariffProfile data={data?.result} />
                </>
            )}
        </>
    )
}

export { ProfilePage }
