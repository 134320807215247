import React from 'react'
import './Loading.css'

const Loading = () => {
    return (
        <div className="loading">
            <img src="/assets/images/loading_w200.gif" alt="Загрузка..." />
        </div>
    )
}

export { Loading }
