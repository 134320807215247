import React, { useState, useEffect } from 'react'
import { useDebounce } from '../../../../components/hooks/useDebounce'
import { InputFields } from '../../../../UI/InputFields/InputFields'
import {
    useGetTrackInfoMutation,
    useTrackUpdateInfoMutation,
    useUploadTrackMutation,
} from '../../api/releaseListAPI'
import moment from 'moment'
import { YtRadioBoolean } from '../../../../UI/YtRadioBoolean/YtRadioBoolean'
import { YtSlider } from '../../../../UI/YtSlider/YtSlider'
import { MultipleTextInput } from '../../../../components/MultipleTextInput/MultipleTextInput'
import { DoubleMultipleTextInput } from '../../../../components/DoubleMultipleTextInput/DoubleMultipleTextInput'
import { YtSelect } from '../../../../UI/YtSelect/YtSelect'
import { MainButton } from '../../../../UI/MainButton/MainButton'
import { UploadFile } from '../../../../components/UploadFile/UploadFile'
import ReplyIcon from '@mui/icons-material/Reply'
import './TrackInfo.css'

const TrackInfo = ({ goNextPage, currentTrackUID, setCurrentTrackUID, setActiveTrackListBlock }) => {
    const [getTrackInfo, { isLoading: isLoadingTrackInfo }] =
        useGetTrackInfoMutation()

    const [uploadTrack, { isLoading: isLoadingUpload }] =
        useUploadTrackMutation()

    const [updateTrackInfo, { isLoading }] = useTrackUpdateInfoMutation()

    const handlerUpdateTrackInfo = async (Obj) => {
        try {
            const trackInfo = await updateTrackInfo(Obj).unwrap()
            console.log('trackInfo', trackInfo)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }

    const debouncedUpdate = useDebounce(handlerUpdateTrackInfo, 500)

    const currentDATE = Number(moment().format('YYYY'))

    const [trackDATA, setTrackDATA] = useState({
        name: '',
        track_year: currentDATE,
        remix_author: '',
        isrc: '',
        insta_index: '',
        main_song: false,
        filthy_lang: false,
        is_instrumental: false,
        song_text: '',

        genre: null,

        title_lang: '',
        lang: '',

        sort: 0,
        uid: '',
    })
    const [uploadBlock, setUploadBlock] = useState(false)
    const [singers, setSingers] = useState([''])
    const [singersOther, setSingersOther] = useState([''])
    const [composer, setComposer] = useState([['', '']])
    const [arranger, setArranger] = useState([['', '']])
    const [authors, setAuthors] = useState([['', '']])
    const [requireFields, setRequireFields] = useState({
        r_name: false,
        r_name_dirty: false,
        r_singers: false,
        r_singers_dirty: false,
        r_composer: false,
        r_composer_dirty: false,
        r_authors: false,
        r_authors_dirty: false,
        // r_title_lang: false,
        // r_title_lang_dirty: false,
        // r_lang: false,
        // r_lang_dirty: false,
        r_song_text: false,
        r_song_text_dirty: false,
    })
    const [file, setFile] = useState({ name: '' })
    const [finishBTN, setFinishBTN] = useState(false)

    // console.log('requireFields --------', requireFields)

    const onBlurDirty = (e) => {
        let newArg = { ...requireFields }
        newArg[`r_${e.target.name}_dirty`] = true
        setRequireFields(newArg)
    }

    useEffect(() => {
        fetchGetTrackInfo()
    }, [currentTrackUID])

    useEffect(() => {
        let newArg = { ...requireFields }

        if (trackDATA.name != '') newArg.r_name = true
        else newArg.r_name = false

        if (singers[0] != '') newArg.r_singers = true
        else newArg.r_singers = false

        if (composer[0][0] != '' && composer[0][1] != '')
            newArg.r_composer = true
        else newArg.r_composer = false

        if (authors[0][0] != '' && authors[0][1] != '') newArg.r_authors = true
        else newArg.r_authors = false

        if (trackDATA.song_text != '' || trackDATA.is_instrumental)
            newArg.r_song_text = true
        else newArg.r_song_text = false

        setRequireFields(newArg)
    }, [
        trackDATA.name,
        singers[0],
        composer[0][0],
        composer[0][1],
        authors[0][0],
        authors[0][1],
        trackDATA.song_text,
        trackDATA.is_instrumental,
    ])

    useEffect(() => {
        if (
            requireFields.r_name &&
            requireFields.r_singers &&
            requireFields.r_composer &&
            requireFields.r_authors &&
            requireFields.r_song_text
        )
            setUploadBlock(true)
        else setUploadBlock(false)

        if (
            requireFields.r_name &&
            requireFields.r_singers &&
            requireFields.r_composer &&
            requireFields.r_authors &&
            requireFields.r_song_text &&
            file.name != ''
        )
            setFinishBTN(true)
        else setFinishBTN(false)
    }, [
        requireFields.r_name,
        requireFields.r_singers,
        requireFields.r_composer,
        requireFields.r_authors,
        requireFields.r_song_text,
        file.name,
    ])

    const fetchGetTrackInfo = async () => {
        if (currentTrackUID == undefined) return

        try {
            const RequestTrackInfo = await getTrackInfo({
                uid: currentTrackUID,
            }).unwrap()

            let newArg = {}

            if (RequestTrackInfo.result.name == null) newArg.name = ''
            else newArg.name = RequestTrackInfo.result.name

            if (RequestTrackInfo.result.remix_author == null)
                newArg.remix_author = ''
            else newArg.remix_author = RequestTrackInfo.result.remix_author

            if (RequestTrackInfo.result.isrc == null) newArg.isrc = ''
            else newArg.isrc = RequestTrackInfo.result.isrc

            if (RequestTrackInfo.result.insta_index == null)
                newArg.insta_index = ''
            else newArg.insta_index = RequestTrackInfo.result.insta_index

            if (RequestTrackInfo.result.song_text == null) newArg.song_text = ''
            else newArg.song_text = RequestTrackInfo.result.song_text

            if (RequestTrackInfo.result.main_song == null)
                newArg.main_song = false
            else newArg.main_song = RequestTrackInfo.result.main_song

            if (RequestTrackInfo.result.filthy_lang == null)
                newArg.filthy_lang = false
            else newArg.filthy_lang = RequestTrackInfo.result.filthy_lang

            if (RequestTrackInfo.result.is_instrumental == null)
                newArg.is_instrumental = false
            else
                newArg.is_instrumental = RequestTrackInfo.result.is_instrumental

            if (RequestTrackInfo.result.track_year == null)
                newArg.track_year = currentDATE
            else newArg.track_year = RequestTrackInfo.result.track_year

            if (RequestTrackInfo.result.title_lang == null)
                newArg.title_lang = '_'
            else newArg.title_lang = RequestTrackInfo.result.title_lang

            if (RequestTrackInfo.result.lang == null) newArg.lang = '_'
            else newArg.lang = RequestTrackInfo.result.lang

            if (RequestTrackInfo.result.file == true)
                setFile({ name: 'Файл загружен' })
            else setFile({ name: '' })

            newArg.file = RequestTrackInfo.result.file

            setTrackDATA(newArg)
            setSingers(RequestTrackInfo.result.singers)
            setSingersOther(RequestTrackInfo.result.singers_other)
            setComposer(RequestTrackInfo.result.composer)
            setArranger(RequestTrackInfo.result.arranger)
            setAuthors(RequestTrackInfo.result.authors)

            console.log(RequestTrackInfo)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }

    const onChangeUpdate = (e) => {
        let newArg = { ...trackDATA },
            sendDATA = { uid: currentTrackUID }

        sendDATA[e.target.name] = e.target.value
        newArg[e.target.name] = e.target.value

        setTrackDATA(newArg)
        debouncedUpdate(sendDATA)
    }

    const onChangeUpdateRadio = (e) => {
        let newArg = { ...trackDATA },
            sendDATA = { uid: currentTrackUID }

        if (e.target.value == 'true') {
            sendDATA[e.target.name] = true
            newArg[e.target.name] = true
        } else {
            sendDATA[e.target.name] = false
            newArg[e.target.name] = false
        }

        setTrackDATA(newArg)
        debouncedUpdate(sendDATA)
    }

    const onChangeSlider = (e, newValue) => {
        let newArg = { ...trackDATA },
            sendDATA = { uid: currentTrackUID }

        sendDATA[e.target.name] = newValue
        newArg[e.target.name] = newValue

        setTrackDATA(newArg)
        debouncedUpdate(sendDATA)
    }

    const marks = [
        {
            value: 2010,
            label: '2010',
        },
        {
            value: currentDATE,
            label: currentDATE,
        },
    ]

    const language_release = [
        { label: 'Выберите язык', value: '_' },
        { label: 'Русский', value: 'Русский' },
        { label: 'Английский', value: 'Английский' },
        { label: 'Немецкий', value: 'Немецкий   ' },
        { label: 'Французский', value: 'Французский' },
        { label: 'Китайский', value: 'Китайский' },
        { label: 'Японский', value: 'Японский' },
    ]

    return (
        <>
            <div
                className="ars3_back_tracklist_btn"
                onClick={() => {
                    setCurrentTrackUID('')
                    setActiveTrackListBlock(true)
                }}
            >
                <ReplyIcon />
            </div>
            <InputFields
                label="Название трека"
                value={trackDATA.name}
                name="name"
                onChange={onChangeUpdate}
                onBlur={onBlurDirty}
                error={
                    requireFields.r_name_dirty && !requireFields.r_name
                        ? true
                        : false
                }
                helperText={
                    requireFields.r_name_dirty && !requireFields.r_name
                        ? 'Поле должно быть заполненным'
                        : ''
                }
                required
            />
            <MultipleTextInput
                textLabel="Исполнитель"
                mainArg={singers}
                setArg={setSingers}
                UID={currentTrackUID}
                debounced={debouncedUpdate}
                name="singers"
                requireFields={requireFields}
                setRequireFields={setRequireFields}
            />
            <MultipleTextInput
                textLabel="При участии"
                mainArg={singersOther}
                setArg={setSingersOther}
                UID={currentTrackUID}
                debounced={debouncedUpdate}
                name="singers_other"
            />
            <DoubleMultipleTextInput
                textLabel="Фамилия и имя композитора"
                mainArg={composer}
                setArg={setComposer}
                debounced={debouncedUpdate}
                UID={currentTrackUID}
                name="composer"
                requireFields={requireFields}
                setRequireFields={setRequireFields}
            />
            <DoubleMultipleTextInput
                textLabel="Фамилия и имя автора текста"
                mainArg={authors}
                setArg={setAuthors}
                debounced={debouncedUpdate}
                UID={currentTrackUID}
                name="authors"
                requireFields={requireFields}
                setRequireFields={setRequireFields}
            />
            <DoubleMultipleTextInput
                textLabel="Фамилия и имя аранжировщика"
                mainArg={arranger}
                setArg={setArranger}
                debounced={debouncedUpdate}
                UID={currentTrackUID}
                name="arranger"
            />
            <YtSelect
                idLabel="title_lang_label"
                id="track_title_lang"
                name="title_lang"
                value={trackDATA.title_lang}
                label="Язык заголовка песни"
                onChange={onChangeUpdate}
                data={language_release}
            />
            <YtSelect
                idLabel="lang_label"
                id="track_lang"
                name="lang"
                value={trackDATA.lang}
                label="Язык песни"
                onChange={onChangeUpdate}
                data={language_release}
            />
            <YtSlider
                textLabel={`Год записи ${trackDATA.track_year}`}
                min={2010}
                step={1}
                max={currentDATE}
                value={Number(trackDATA.track_year)}
                marks={marks}
                onChange={onChangeSlider}
                name="track_year"
            />
            <InputFields
                label="Имя автора ремикса"
                value={trackDATA.remix_author}
                name="remix_author"
                onChange={onChangeUpdate}
            />
            <InputFields
                label="ISRC-код"
                value={trackDATA.isrc}
                name="isrc"
                onChange={onChangeUpdate}
            />
            <InputFields
                label="Начало трека для TikTok / Instagram"
                value={trackDATA.insta_index}
                name="insta_index"
                onChange={onChangeUpdate}
            />
            <YtRadioBoolean
                textLabel="Основная песня альбома"
                onChange={onChangeUpdateRadio}
                name="main_song"
                value={trackDATA.main_song}
            />
            <YtRadioBoolean
                textLabel="Нецензурная лексика"
                onChange={onChangeUpdateRadio}
                name="filthy_lang"
                value={trackDATA.filthy_lang}
            />
            <YtRadioBoolean
                textLabel="Инструментальный трек"
                onChange={onChangeUpdateRadio}
                name="is_instrumental"
                value={trackDATA.is_instrumental}
            />
            {!trackDATA.is_instrumental ? (
                <InputFields
                    label="Текст песни"
                    value={trackDATA.song_text}
                    name="song_text"
                    onChange={onChangeUpdate}
                    multiline
                    rows={14}
                    onBlur={onBlurDirty}
                    error={
                        requireFields.r_song_text_dirty &&
                        !requireFields.r_song_text
                            ? true
                            : false
                    }
                    helperText={
                        requireFields.r_song_text_dirty &&
                        !requireFields.r_song_text
                            ? 'Поле должно быть заполненным'
                            : ''
                    }
                    required
                />
            ) : null}

            {trackDATA.file || uploadBlock ? (
                <UploadFile
                    file={file}
                    setFile={setFile}
                    uploadTrack={uploadTrack}
                    UID={currentTrackUID}
                    isLoadingUpload={isLoadingUpload}
                />
            ) : null}

            {finishBTN && !isLoadingUpload ? (
                <MainButton
                    color="yt_old_dark"
                    title="Завершить"
                    disabled={!uploadBlock}
                    onClick={() => goNextPage(4)}
                />
            ) : null}
        </>
    )
}

export { TrackInfo }
