import { useGetTariffUserUpdateMutation } from "./profileAPI"

export function useUpdateTariffUser() {

    const [updateTariff, { isLoading }] =
    useGetTariffUserUpdateMutation()
    

    const handlerUpdateTariff = async (obj) => {
        try {
            const newTariff = await updateTariff(obj).unwrap()
            console.log(newTariff)
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }


    return {
        handlerUpdateTariff,
    }
}