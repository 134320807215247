import React from 'react'
import {
    FormControl,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material'

const YtRadioBoolean = ({ textLabel, ...props }) => {
    return (
        <FormControl fullWidth>
            <Typography>{textLabel}</Typography>
            <RadioGroup {...props} style={{ justifyContent: 'center' }} row>
                <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Нет"
                />
                <FormControlLabel value={true} control={<Radio />} label="Да" />
            </RadioGroup>
        </FormControl>
    )
}

export { YtRadioBoolean }
