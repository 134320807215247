import React, { useState } from 'react'
import { ModalBoolean } from '../../components/Modals/ModalBoolean/ModalBoolean'
import { useDeleteRelease } from '../../modules/Release/api/useDeleteRelease'
import './ReleaseItem.css'

const ReleaseItem = ({ data, setCurrentUID, goNextPage }) => {

    const [activeModal, setActiveModal] = useState(false)
    const { handlerDeleteRelease } = useDeleteRelease()

    return (
        <div className="release_item">
            <div className="release_item_top">
                <div className="release_ava">
                    <img src={data.ava_link} alt={data.name} />
                </div>
                <div className="release_main_info">
                    <div className="release_title">
                        <span>Название:</span>
                        {data.name}
                    </div>
                    <div className="release_author">
                        <span>Исполнитель:</span>
                        {data.main_singers_print}
                    </div>
                    <div className="release_type">
                        <span>Тип релиза:</span>
                        {data.type_name}
                    </div>
                    <div className="release_date">
                        <span>Дата релиза:</span>
                        {data.public_date_print}
                    </div>
                    {data.upc ? (
                        <div className="release_upc">
                            <span>UPС код:</span>
                            {data.upc}
                        </div>
                    ) : null}
                    <div className={`release_status ${data.status_style}`}>
                        {data.status_name}
                    </div>
                </div>
            </div>
            <div className="release_item_bottom">
                <div onClick={() => {
                    setCurrentUID(data.uid)
                    goNextPage(1)
                }}>
                    <img src="assets/icon/icon_edit.svg" alt="edit" />
                </div>
                <div onClick={() => setActiveModal(true)}>
                    <img src="assets/icon/icon_delete_trash.svg" alt="del" />
                </div>
            </div>
            <ModalBoolean
                activeModal={activeModal}
                setActiveModal={setActiveModal}
                clickYes={() => handlerDeleteRelease(data.uid)}
            >
                {`Вы точно хотите удалить ${data.name}?`}
            </ModalBoolean>
        </div>
    )
}

export { ReleaseItem }
