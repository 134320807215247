import { useGetUserPasswordUpdateMutation } from "./profileAPI"

export function useUpdateProfilePassword() {
   
    const [updatePassword, {isLoading}] = useGetUserPasswordUpdateMutation()

    const handlerUpdatePass = async (obj) => {
        try {
            const newPass = await updatePassword(obj).unwrap()
            console.log('newPass', newPass)
            console.log('УСПЕХ')
        } catch (e) {
            console.log('ОШИБКА - ', e)
        }
    }
   
    return {
        handlerUpdatePass
    }
}
