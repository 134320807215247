import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import moment from 'moment'

export const profileAPI = createApi({
    reducerPath: 'profileAPI',
    tagTypes: ['User'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.yourtunes.net/api/v2/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token
            if (token) {
                // headers.set('authorization', `Bearer ${token}`)
                headers.set(
                    'authorization',
                    `Bearer 6321dfb66102djpjtf995hq1bqwemy99qy3hdncramq`
                )
            }
            return headers
        },
    }),
    endpoints: (build) => ({
        getUserInfo: build.query({
            query: () => `user/get_info`,
            transformResponse(data, meta, arg) {
                if (data.result.tarif_end == null)
                    data.result.tarif_end_print = 'Дата отсутствует'
                else
                    data.result.tarif_end_print = moment(
                        data.result.tarif_end,
                        'YYYY-MM-DD'
                    ).format('DD.MM.YYYY')

                data.result.fixed_tarif_description =
                    data.result.tarif_description?.split('\r\n')

                return data
            },
            providesTags: ['User'],
        }),
        getUserInfoUpdate: build.mutation({
            query: (body) => ({
                url: `user/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['User'],
        }),
        getUserPasswordUpdate: build.mutation({
            query: (body) => ({
                url: `user/update_password`,
                method: 'POST',
                body,
            }),
        }),
        getUserSocialUpdate: build.mutation({
            query: (body) => ({
                url: `user/update_soc`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['User'],
        }),
        getUserAvaUpdate: build.mutation({
            query: (body) => ({
                url: `user/update_ava`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['User'],
        }),
        getTariffList: build.query({
            query: () => `tarif/list`,
            transformResponse(data, meta, arg) {
                data.modalTariff = null
                
               

                let modalTariffObjTemp = {}

                for (let i = 0; i < data.result.length; i++) {
                    if (data.result[i].count == 1) {
                        modalTariffObjTemp[data.result[i].title] = {
                            title: data.result[i].title,
                            values:  [{title: 'Месяц', value: data.result[i].id, name: 'tarifRadioBtn', price: data.result[i].price }],
                            description:
                                data.result[i].description.split('\r\n')
                         
                        }
                    } else {
                        modalTariffObjTemp[data.result[i].title].values = [
                            ...modalTariffObjTemp[data.result[i].title].values = [...modalTariffObjTemp[data.result[i].title].values,  {title: 'Год', value: data.result[i].id, name: 'tarifRadioBtn', price: data.result[i].price }]
                        ]
                    }
                }

             


                data.modalTariff = Object.values(modalTariffObjTemp)

        

                return data
            },
        }),
        getTariffUserUpdate: build.mutation({
            query: (body) => ({
                url: `user/update_tarif`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['User'],
        }),
    }),
})

export const {
    useGetUserInfoQuery,
    useGetUserInfoUpdateMutation,
    useGetUserPasswordUpdateMutation,
    useGetUserSocialUpdateMutation,
    useGetUserAvaUpdateMutation,
    useGetTariffListQuery,
    useGetTariffUserUpdateMutation
} = profileAPI
